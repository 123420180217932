import {stockSingleton} from "../../../classes/Inventario"  
import { Producto } from '../../../classes/Producto'; 
 

export async function getProducts() {  
    let stock = await stockSingleton.get() 
    return stock
} 

export async function edit(name, value, itm) {
    if (value == ''){ value = itm.value }
    if (name == ''){ name = itm.name }

    var prod = new Producto(itm.sku)  
    await prod.edit(name, value) 
    var products = await getProducts()
    return products 

}

export async function trash(itm) { 
    var prod = new Producto(itm.sku)  
    await prod.trash() 
    var products = await getProducts()
    return products
}
