import { dispatchCustomEvent } from '../../../utils/dispatch';
import ModalBtn from '../../../moleculas/modalButton/hub/view';

import ThirdsTable from '../thirdsTable/view';

function ThirdsModal(props) {

    function update() {
        dispatchCustomEvent('update-thirds', [])
    }

    return (
        <ModalBtn
            id={props.id ? "thirds-"+props.id : "thirds"}
            title={props.text ? props.text : "Clientes"}
            onClick={update}
        >
            <ThirdsTable
                id={props.id ? "thirds-"+props.id : "thirds"}
                forceLoadData={true}
                type={props.type ? props.type : "client"}
                SetTerceroAux={props.SetTerceroAux}
                setTerceros={props.setTerceros}
            ></ThirdsTable>
        </ModalBtn>
    )
}

export default ThirdsModal