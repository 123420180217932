//Classes
import { userSingleton } from "../../classes/User"

//Context
import { useUserAuth } from "../../../AuthContext";
//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

//Controller import
import { openRegister } from './controller'
import { useEffect, useState } from "react";
import { lang } from "../../utils/langs";

function Login(props) {

    const { logIn } = useUserAuth();
    const [user, setUser] = useState(userSingleton);

    useEffect(() => {
        if (user.uid) {
            window.location.replace('/hub')
        }
    }, [user])

    async function login(email, password) {
        logIn(email, password).then((res) => {
            var aux = userSingleton
            aux.setUid(res.user.uid)
            setUser(aux)
            window.location.replace('/hub')
        }).catch((error) => {
            var keys = [
                "auth/wrong-password",
                "auth/claims-too-large",
                "auth/email-already-exists",
                "auth/id-token-expired",
                "auth/id-token-revoked",
                "auth/insufficient-permission",
                "auth/invalid-argument",
                "auth/invalid-claims",
                "auth/invalid-creation-time",
                "auth/invalid-disabled-field",
                "auth/invalid-display-name",
                "auth/invalid-email-verified",
                "auth/invalid-hash-algorithm",
                "auth/invalid-hash-block-size",
                "auth/invalid-hash-derived-key-length",
                "auth/invalid-hash-key",
                "auth/invalid-hash-memory-cost",
                "auth/invalid-hash-parallelization",
                "auth/invalid-hash-rounds",
                "auth/invalid-hash-salt-separator",
                "auth/invalid-id-token",
                "auth/invalid-last-sign-in-time",
                "auth/invalid-page-token",
                "auth/invalid-password",
                "auth/invalid-password-hash",
                "auth/invalid-password-salt",
                "auth/invalid-photo-url",
                "auth/invalid-provider-data",
                "auth/invalid-oauth-responsetype",
                "auth/invalid-session-cookie-duration",
                "auth/invalid-uid",
                "auth/invalid-user-import",
                "auth/maximum-user-count-exceeded",
                "auth/missing-hash-algorithm",
                "auth/missing-oauth-client-secret",
                "auth/phone-number-already-exists",
                "auth/project-not-found",
                "auth/reserved-claims",
                "auth/session-cookie-expired",
                "auth/session-cookie-revoked",
                "auth/uid-already-exists",
                "auth/admin-restricted-operation",
                "auth/app-not-authorized",
                "auth/app-not-installed",
                "auth/captcha-check-failed",
                "auth/code-expired",
                "auth/cordova-not-ready",
                "auth/cors-unsupported",
                "auth/credential-already-in-use",
                "auth/custom-token-mismatch",
                "auth/requires-recent-login",
                "auth/dependent-sdk-initialized-before-auth",
                "auth/dynamic-link-not-activated",
                "auth/email-change-needs-verification",
                "auth/email-already-in-use",
                "auth/emulator-config-failed",
                "auth/expired-action-code",
                "auth/cancelled-popup-request",
                "auth/internal-error",
                "auth/invalid-app-credential",
                "auth/invalid-app-id",
                "auth/invalid-user-token",
                "auth/invalid-auth-event",
                "auth/invalid-verification-code",
                "auth/invalid-continue-uri",
                "auth/invalid-cordova-configuration",
                "auth/invalid-custom-token",
                "auth/invalid-dynamic-link-domain",
                "auth/invalid-email",
                "auth/invalid-emulator-scheme",
                "auth/invalid-api-key",
                "auth/invalid-cert-hash",
                "auth/invalid-credential",
                "auth/invalid-message-payload",
                "auth/invalid-multi-factor-session",
                "auth/invalid-oauth-provider",
                "auth/invalid-oauth-token",
                "auth/invalid-oauth-verifier",
                "auth/invalid-password-reset-code",
                "auth/invalid-persistence-type",
                "auth/invalid-provider-id",
                "auth/invalid-recipient-email",
                "auth/invalid-sender",
                "auth/invalid-verification-id",
                "auth/invalid-tenant-id",
                "auth/mfa-info-not-found",
                "auth/missing-android-pkg-name",
                "auth/missing-continue-uri",
                "auth/missing-iframe-start",
                "auth/missing-ios-bundle-id",
                "auth/missing-multi-factor-info",
                "auth/missing-multi-factor-session",
                "auth/missing-phone-number",
                "auth/missing-verification-code",
                "auth/missing-verification-id",
                "auth/missing-uid",
                "auth/operation-not-allowed",
                "auth/popup-blocked",
                "auth/popup-closed-by-user",
                "auth/provider-already-linked",
                "auth/quota-exceeded",
                "auth/redirect-cancelled-by-user",
                "auth/redirect-operation-pending",
                "auth/rejected-credential",
                "auth/second-factor-already-in-use",
                "auth/tenant-id-mismatch",
                "auth/timeout",
                "auth/user-token-expired",
                "auth/too-many-requests",
                "auth/unauthorized-continue-uri",
                "auth/unsupported-persistence-type",
                "auth/unsupported-tenant-operation",
                "auth/unverified-email",
                "auth/user-cancelled",
                "auth/user-disabled",
                "auth/user-mismatch",
                "auth/user-not-found",
                "auth/user-signed-out",
                "auth/weak-password",
                "auth/web-storage-unsupported"
            ];
            keys.map((key) => {
                if (error.message.includes(key)) {
                    window.alert(lang('es', key))
                }
            });
        })
    }

    return (
        <div className='loginbox'>
                <div className="land-top-bar">
                    <h1 onClick={() => window.location.replace('/')}># Easypos</h1>
                </div>
                <h2 className="log-title">Inicia Sesion en Easypos</h2>
                <form className="login-form" id="login-form" action="javascript:void(0);" onSubmit={(data) => [data.preventDefault(), login(data.target[0].value, data.target[1].value)]}>
                    <Form.Group className="mb-3" id="formBasicEmail">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control id='register-email' type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group className="mb-3" id="formBasicPassword">
                        <Form.Label>Contraseña:</Form.Label>
                        <Form.Control type="password" placeholder="Contraseña" />
                    </Form.Group>
                    <div className="btn-group">
                        <Button className="log-btn" variant="primary" type="submit" >
                           Iniciar sesion
                        </Button>
                    </div>
                    <hr></hr>
                </form>
                <button className="log-register" variant="primary" type="click" onClick={() => openRegister('register-email')}>
                    No tienes una cuenta? Registrate en Easypos
                </button>
            </div>
    )

}

export default Login
