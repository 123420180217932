import {
    initMercadoPago,
    createCardToken,
    CardNumber,
    SecurityCode,
    ExpirationDate,
} from '@mercadopago/sdk-react';
import { Form } from 'react-bootstrap';
import { ButtonBasic } from '../../atomos/buttons';
import { useEffect, useState } from 'react';
import { createSuscription } from '../../domain/usecase/suscriptions/suscribe';
import { updateSuscription } from '../../domain/usecase/suscriptions/update-suscription';
import { getIdTypes } from '../../views/planes/controller';
 
const CardToken = ({type, plan}) => {
    
    const [idTypes, SetIdTypes] = useState([])

    initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY);

    useEffect(() => {
        getIdTypes().then((res)=>{ SetIdTypes(res) }) 
    }, [])
    
    const getToken = async () => { 
        const cardTokenResponse = await createCardToken({
            cardholderName: window.document.getElementById("card-name").value,
            identificationType: window.document.getElementById("card-id-type").value,
            identificationNumber: window.document.getElementById("card-id").value,
        }) 
        if (type == "suscribe"){
            createSuscription({
                "plan": plan,
                "paymethod": cardTokenResponse 
            }) 
        }
        if(type == "update"){
            updateSuscription({
                "plan": plan,
                "paymethod": cardTokenResponse 
            })
        }
    }

    return (
        <div className="card-info">
            <div>
                <p>Datos de la tarjeta:</p>
                <div className="card-data">
                    <CardNumber style={{ height: "38px" }} placeholder='Numero de tarjeta' />
                </div>
                <div style={{ display: "flex" }}>
                    <div className="card-data">
                        <SecurityCode style={{ height: "38px" }} placeholder='Codigo de seguridad' />
                    </div>
                    <div className="card-data">
                        <ExpirationDate style={{ height: "38px" }} placeholder='Fecha Expiracion' mode='short' />
                    </div>
                </div>
                <hr></hr>
                <p>Datos del comprador:</p>
                <Form.Group >
                    <Form.Control id="card-name" placeholder="Name"></Form.Control>
                </Form.Group>
                <Form.Group style={{ display: "flex", margin: "3px" }}>
                    <Form.Select id="card-id-type" style={{ width: "30%", marginRight: "3px" }}>
                        {
                            idTypes?.map(item => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })
                        }

                    </Form.Select>
                    <Form.Control id="card-id" style={{ width: "70%" }} placeholder="id"></Form.Control>
                </Form.Group>
                <hr></hr>
                <ButtonBasic style={{width:"100%"}} onClick={getToken}>Guardar</ButtonBasic> 
            </div>
        </div>
    )
}

export default CardToken;