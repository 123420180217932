//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';
import { BsFillCartCheckFill } from 'react-icons/bs';
import { FaClone } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';

import { EmptyArray, FactoryEmpty } from '../../../domain/models/empty/empty'

//React import
import { useEffect, useState } from 'react';

//Controller import
import { edit } from './controller'

//Widgets import
import { carSingleton } from "../../../classes/Carrito"
import Loading from '../../../atomos/loading/loadingBtn/view';
import { BiEdit, BiSave } from 'react-icons/bi';
import Table from '../../../moleculas/tablas/view';
import ButtonAction from '../../../atomos/buttons/buttonAction/view';
import ProductsRepositoryImp from '../../../infrastructure/products/products-repository-implementation'
import { userSingleton } from '../../../classes/User';
import NewProduct from '../newProduct/view';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import EditProduct from '../editProduct/view';


function ProductsTable(props) {

    const productRepository = new ProductsRepositoryImp();

    const [inventarioFull, SetInventarioFull] = useState([]);
    const [inventario, SetInventario] = useState([]);
    const [loading, SetLoading] = useState(false)
    const [temp, SetTemp] = useState(undefined)
    const [open, SetOpen] = useState(true)
    var vdinput;


    useEffect(() => {
        var forceLoadData = props.forceLoadData ? props.forceLoadData : false
        if (forceLoadData && inventario?.length === 0){
            getProduct()
        }

        window.addEventListener('update-stock', handleUpdate);
        window.addEventListener('btn-inventario-add-car', handleBtnAdd);
        window.addEventListener('btn-inventario-clone-item', handleBtnClone);
        window.addEventListener('btn-inventario-delete-item', handleBtnDelete);
        return () => {
            window.removeEventListener('update-stock', handleUpdate);
            window.removeEventListener('btn-inventario-add-car', handleBtnAdd);
            window.removeEventListener('btn-inventario-clone-item', handleBtnClone);
            window.removeEventListener('btn-inventario-delete-item', handleBtnDelete);
        };
    }, []);

    function getProduct() {
        SetInventarioFull([])
        SetInventario([])
        const productSuscription = productRepository.get(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                if (data?.length == 0){
                    SetInventarioFull(EmptyArray)
                    SetInventario(EmptyArray)
                }else{
                    SetInventarioFull(data)
                    SetInventario(data)
                }
            },
            (error) => {
                console.error(error)
                SetInventario(undefined)
            }
        )
        return () => productSuscription.unsuscribe()
    }

    function handleUpdate(event) {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();
        getProduct()
    }

    function handleBtnAdd(event) {
        props.setCarrito(carSingleton.add(event.detail.data))
    }

    function handleBtnClone(event) {
        let cnt = prompt("Precio:", 0);
        var aux = {
            id: event.detail.data.id,
            name: event.detail.data.name,
            sell_tax: event.detail.data.sell_tax,
            sell_taxes_id: event.detail.data.sell_taxes_id,
            sku: event.detail.data.sku,
            skutmp: event.detail.data.sku + cnt,
            value: parseInt(cnt),
        }
        props.setCarrito(carSingleton.add(aux))
    }

    function handleBtnDelete(event) {
        const userConfirmed = window.confirm("¿Estás seguro de que deseas eliminar este producto?");

        if (userConfirmed) {
            const productSuscription = productRepository.delete(userSingleton.uid, userSingleton.cid, event.detail.data.sku).subscribe(
                () => {
                    window.alert("Producto eliminado");
                    getProduct();
                },
                (error) => {
                    console.error(error);
                }
            );
            return () => productSuscription.unsubscribe();
        } else {
            return;
        }
    }


    function findAndAdd(e) {
        var aux_search = e.target.value
        var aux_inv = inventarioFull.filter(inventario => (inventario?.sku.toLowerCase().includes(aux_search.toLowerCase()) || inventario?.name.toLowerCase().includes(aux_search.toLowerCase())))
        SetInventario(aux_inv)
        if (aux_search.length == 0) {
            SetInventario(inventarioFull)
        }
    }

    function clean() {
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
    }

    return (
        <div >
            <div className='modal-backgroud' hidden={open}>
                <div className='modal-box inventario-update'>
                    <p style={{ "position": "absolute", "right": "0px", "top": "0px", "margin": "15px" }} onClick={() => [SetOpen(true)]}>X</p>
                    <div>
                        <Form action="javascript:void(0);" onSubmit={(data) => [SetLoading(true), data.preventDefault(), edit(data.target[0].value, data.target[1].value, temp).then((res) => [SetLoading(false), SetOpen(true), SetInventario(res), clean()])]}>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder={temp ? temp.name : ''} />
                            </Form.Group>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Venta</Form.Label>
                                <Form.Control type="text" placeholder={temp ? temp.value : ''} />
                            </Form.Group>
                            <Button style={{ position: "relative", top: "10px", width: '100%', marginLeft: "0px" }} variant="primary" type="submit">
                                <Loading loading={loading}> <BiSave></BiSave> Guardar </Loading>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            <div ref={props.ref} className='search-box'>
                <Form.Control placeholder='Busca productos por sku o nombre' onChange={(e) => findAndAdd(e)} type='search' className='vd-input' list='lista' value={vdinput}  ></Form.Control>
                <datalist className='vd-datalist' id='lista'>
                    {
                        inventario?.map((item, idx) => {
                            return (
                                <option key={idx} value={item.name}>{item.name}</option>
                            )
                        })
                    }
                </datalist>
            </div>
            <Table
                emptyMessage="Aun no tienes productos"
                emptyChild={<NewProduct></NewProduct>}
                maxHeight={props.tableHeight ? props.tableHeight : "35vh"}
                headersList={["Sku", "Nombre", "Precio", "Acciones"]}
                data={inventario}
                excludeList={["buy_tax", "id", "sell_tax", "sell_taxes_id", "skutmp"]}
            >
                <ButtonAction hidden={props.hiddenAction ? props.hiddenAction : false} btnKey="inventario-add-car" className='action-btn'>
                    <BsFillCartCheckFill></BsFillCartCheckFill>
                </ButtonAction>
                <ButtonAction hidden={props.hiddenAction ? props.hiddenAction : false} btnKey="inventario-clone-item" className='action-btn'>
                    <FaClone></FaClone>
                </ButtonAction>
                {/* <ButtonAction hidden={props.enableDelete ? !props.enableDelete : true} btnKey="inventario-delete-item" className='action-btn'>
                    <MdDelete></MdDelete>
                </ButtonAction>  */}
                <EditProduct hidden={props.enableEdit ? !props.enableEdit : true} btnKey="inventario-edit-item" className='action-btn'></EditProduct>
            </Table>
        </div>
    )
}

export default ProductsTable
