type Empty = { 
    message : string,
}

class FactoryEmpty {
    public static create(message:string) : Empty {
        return { 
            message,
        }
    }
}

const EmptyArray = [FactoryEmpty.create("empty_array")]

export { Empty, FactoryEmpty, EmptyArray }