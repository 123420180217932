export function enviarMensaje() {
    var telefono = '3042250026';
    var mensaje = 'Hola%20me%20gustaria%20activar%20la%20factura%20electronica';
    var link = 'https://wa.me/' + telefono + '?text=' + mensaje;
    window.open(link);
}

export function base64ToUint8Array(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export function createPdfBlob(base64) {
    const byteArray = base64ToUint8Array(base64);
    return new Blob([byteArray], { type: 'application/pdf' });
}

export function createPdfUrl(blob) {
    return URL.createObjectURL(blob);
} 