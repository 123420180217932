import "./style.css"

import { IoMdApps } from "react-icons/io";
import Button from 'react-bootstrap/Button';
import { ButtonBasic } from "../../atomos/buttons";

function ButtonGroup(props) {
    return (
        <div className="button-group">
            <ButtonBasic onClick={() => [window.location.replace('/hub')]}><IoMdApps/>EasyHub</ButtonBasic> 
            {props.children}
        </div>
    )
}

export default ButtonGroup