import { ReportsRepository } from '../../domain/models/reports/gateway/reports-repository';
import { Reports, ReportsKey } from '../../domain/models/reports/reports';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class ReportsRepositoryImp extends ReportsRepository {
  list(uid: string, cid: string | null): Observable<Reports[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Reports[]>(`reports`);
  }

  get(uid: string, cid: string | null, from_date: string, to_date: string, report: string): Observable<ReportsKey> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<ReportsKey>(`reports/${report}?from=${from_date}&to=${to_date}`);
  }
}
