import { createContext, useContext, useEffect, useState } from "react";
import { userSingleton } from "./app/classes/User";
import { Admin } from "./roles/admin";
import { Cashier } from "./roles/cashier";
import { Incognit } from "./roles/incognit";
import { Accountant } from "./roles/accountant";



const RoleContext = createContext();

export function UserRoleProvider() { 
    
    const [role, setRole] = useState(new Incognit().getRoutes()); 
    
    var roles = {
        0: new Incognit(),
        1: new Admin(),
        2: new Accountant(),
        3: new Cashier()
    }

    useEffect(() => { 
        setRole(roles[userSingleton.roles_id].getRoutes()) 
    }, []);

    return (
        <RoleContext.Provider value={{  }}>
            {
                role
            }
        </RoleContext.Provider>
    );
}

export function useUserRole() {
    return useContext(RoleContext);
}