//Custo css import
import './style.css'

//Boostrap import  
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BiSave } from 'react-icons/bi';

//React import 
import { useEffect, useState } from 'react';
import Loading from '../../../atomos/loading/loadingBtn/view';

//Controller import  
import { guardar, getTypes } from './controller' 
import ModalBtn from '../../../moleculas/modalButton/hub/view';
import { dispatchCustomEvent } from '../../../utils/dispatch';
import ProductsRepositoryImp from '../../../infrastructure/products/products-repository-implementation'
import TaxRepositoryImp from '../../../infrastructure/taxes/tax-repository-implementation'
import { userSingleton } from '../../../classes/User';
import { ProductFactory } from '../../../domain/models/products/products';

function NewProduct(props) {
 
    const productRepository = new ProductsRepositoryImp();
    const taxRepository = new TaxRepositoryImp();

    var [selltaxes, SetSellTaxes] = useState([]) 
    var [loading, SetLoading] = useState(false)
 
    function handleTax() {  
        const taxSuscription = taxRepository.get(userSingleton.uid, userSingleton.cid, "sell").subscribe(
            (data) => { 
                SetSellTaxes(data) 
            },
            (error) => {
                console.log(error)
            }
        )
        return () => taxSuscription.unsubscribe()       
    }

    function clean() {
        SetLoading(false)
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
        var options = document.getElementById('tax_selector')
        options.selectedIndex = 0
    } 
 
    function create(data) {
        SetLoading(true)
        data.preventDefault()
        
        var product = ProductFactory.create(
            0,
            data.target[0].value,
            data.target[1].value,
            data.target[3].value,
            0,
            0,
            data.target[4].value
        ) 
        const productCreate = productRepository.create(userSingleton.uid, userSingleton.cid, product).subscribe(
            () => {
                clean() 
                dispatchCustomEvent("update-stock",[])
                dispatchCustomEvent("close-newProduct-modal", [])
            },
            (error) => {
                var err = error.response.data.message 
                var messages = {
                    "limit_reached": "Ups, alcanzaste tu limite de prductos aumenta tu plan y remueve todos los limites...",
                    "product_exist": "Existe un producto con el mismo sku."
                }
                window.alert(messages[err])
                SetLoading(false)
            }
        )
        return () => productCreate.unsubscribe() 
    }

    return (
        <ModalBtn
                id="newProduct"  
                title="Crear" 
                onClick={handleTax}
        >
            <div>
                <h2 style={{ "position": "absolute", "margin": "15px", "top": "0px" }}>Nuevo Poducto</h2>
            </div>
            <div>
                <Form id="compra-form" action="javascript:void(0);" onSubmit={(data) => {create(data)}}>
                    <Form.Group style={{ width: "95%" }}>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="sku" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Sku</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-name" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="buy-buy-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Compra</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-sell-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Venta</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Impuesto Venta</Form.Label>
                                <Form.Select name="color" id="tax_selector">
                                    <option value={2}>Ninguno</option>
                                    {
                                        selltaxes ?
                                            selltaxes.map((item) => { 
                                                return (
                                                    <option value={item.id}>{item.description}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </Form.Group>
                    <Button className='save-btn' variant="primary" type="submit">
                        <Loading loading={loading}> <BiSave></BiSave> Guardar </Loading>
                    </Button>
                </Form>
            </div>
        </ModalBtn>
    )
}

export default NewProduct