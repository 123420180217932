import { initializeApp } from "firebase/app"; 
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDromSWyBeiUnVeL1tOSjiTEwUNHMic5L8",
  authDomain: "contadordev-4db1f.firebaseapp.com",
  databaseURL: "https://contadordev-4db1f-default-rtdb.firebaseio.com",
  projectId: "contadordev-4db1f",
  storageBucket: "contadordev-4db1f.appspot.com",
  messagingSenderId: "169772717871",
  appId: "1:169772717871:web:747e3b5b4be91b3f3373da"
};

// Initialize Firebase 
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;

// var firebaseConfig = {
//     apiKey: process.env.REACT_APP_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGIN_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID
//   }; 