import { userSingleton } from "../../../classes/User";
import SuscriptionRepositoryImp from "../../../infrastructure/suscriptions/suscription-repository-implementation"; 

const repository = new SuscriptionRepositoryImp();

function createSuscription(body) {  
    const suscription = repository.suscribe(userSingleton.uid, userSingleton.cid, body).subscribe(
        (data) => {  
            if (data["status"] == "authorized"){
                window.alert("Tu suscripcion fue exitosa !!!")
                window.location.replace('/hub')
            }
            if (data["status"] == 401){
                var messages = {
                    "cc_rejected_bad_filled_date": "La  fecha de expiración del tarjeta no es correcta.",
                    "cc_rejected_bad_filled_security_code": "El código de seguridad del tarjeta no es correcto.",
                    "cc_rejected_insufficient_amount": "No hay suficiente saldo en la tarjeta para realizar el pago.",
                    "cc_rejected_other_reason": "Se ha rechazado el pago por un error en la tarjeta. Creo que debes comunicarte con tu banco",
                }
                window.alert(messages[data["code"]])
            } 
            if (data["status"] == 404){
                window.alert("Error creando la suscripcion")
            }
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()       
}

export { createSuscription }