//Custo css import 
import './style.css'

function Bill(props) {   

    return (
        <html id="bill" lang="en"> 
            <head>
                <meta charset="UTF-8"></meta>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta http-equiv="X-UA-Compatible" content="ie=edge"></meta>
                <link rel="stylesheet" href="style.css"></link>
                <title>Receipt example</title>
            </head>
            <body>
                <div class="ticket">
                    <p class="centered">RECEIPT EXAMPLE</p>
                    <p class="centered">line1</p>
                    <p class="centered">line 2</p>
                    <table>
                        <thead>
                            <tr>
                                <th class="quantity">Q.</th>
                                <th class="description">Description</th>
                                <th class="price">$$</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="quantity">1.00</td>
                                <td class="description">ARDUINO UNO R3</td>
                                <td class="price">$25.00</td>
                            </tr>
                            <tr>
                                <td class="quantity">2.00</td>
                                <td class="description">JAVASCRIPT BOOK</td>
                                <td class="price">$10.00</td>
                            </tr>
                            <tr>
                                <td class="quantity">1.00</td>
                                <td class="description">STICKER PACK</td>
                                <td class="price">$10.00</td>
                            </tr>
                            <tr>
                                <td class="quantity"></td>
                                <td class="description">TOTAL</td>
                                <td class="price">$55.00</td>
                            </tr>  
                        </tbody>
                    </table>
                    <p class="centered">Thanks for your purchase!
                        parzibyte.me/blog</p>
                </div>
                <button onClick={()=> window.print()} id="btnPrint" class="hidden-print">Print</button>
                <script src="script.js"></script>
            </body>
        </html>
    )
}

export default Bill