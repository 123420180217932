//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';

//React import
import { useEffect, useState } from 'react';

//Controller import
import { getProducts } from './controller'

//Widgets import
import { HiDocumentReport } from 'react-icons/hi';

import "react-datepicker/dist/react-datepicker.css";
import { dateFormat } from '../../utils/helper';
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import Loading from '../../atomos/loading/loadingBtn/view';
import ModalBtn from '../../moleculas/modalButton/hub/view';

function Datepicker(props) {

    const [open, SetOpen] = useState(true)
    const [loading, SetLoading] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        window.addEventListener('datePicker', handleEvent);
        return () => {
            window.removeEventListener('datePicker', handleEvent);
        };
    }, [])

    function handleEvent(event) {
        if (event.detail.data.loading != undefined) {
            SetLoading(event.detail.data.loading)
        }
    }

    function download() {
        SetLoading(true)
        props.func(dateFormat(startDate), dateFormat(endDate), props.code)
    }

    return (
        <div>
            <ModalBtn
                id={"datepicker" + props.code ? props.code : ""}
                title="Descargar"
            >
                <div className='date-title'>
                    <h2>Fechas de reporte:</h2>
                </div>
                <div className='date-box'>
                    <div>
                        <Form.Label>Desde:</Form.Label>
                        <DatePicker selected={startDate} onChange={(date) => [setStartDate(date)]} />
                    </div>
                    <div>
                        <Form.Label>Hasta:</Form.Label>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>
                </div>
                <div className='date-action'>
                    <Button onClick={() => download()} style={{ width: "300px", height: "45px" }}>
                        <Loading loading={loading}> Descargar </Loading>
                    </Button>
                </div>
            </ModalBtn>
        </div>
    )
}

export default Datepicker
