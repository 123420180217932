import './style.css' 

import Nav from 'react-bootstrap/Nav';
import TabLink from '../../atomos/tabLink/view';

function Tab(props) {
    return (
        <Nav fill variant="tabs">
            {
                props.tabs?.map((tab, idx) => {
                    return (
                        <TabLink key={idx} title={tab.title} eventKey={tab.eventKey} tabKey={props.tabKey}>
                            {props.children}
                        </TabLink>
                    )
                })
            }
        </Nav>
    )
}

export default Tab
