import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

//Views Import   
import { UserAuthContextProvider } from './AuthContext';
import { UserRoleProvider } from './RoleContext'; 

import { ConfigCatProvider } from "configcat-react";

function App() {
  return (
      <ConfigCatProvider sdkKey="8SjcCBtr9EiBLzbktq9QcQ/x1XWLyYDwUmjWuHC58VQ4g"> 
        <UserAuthContextProvider>
          <UserRoleProvider></UserRoleProvider>
        </UserAuthContextProvider>
      </ConfigCatProvider>
  );
}

export default App;
