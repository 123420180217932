import axios, { AxiosInstance } from 'axios';
import { Observable } from 'rxjs'; 

class HttpClient {
  private client: AxiosInstance;

  constructor(uid: string, cid: string | null) {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_URL_POS,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'x-uid': uid,
        'x-cid': cid
      },
    });
  }

  get<T>(url: string): Observable<T> {
    return new Observable((observer) => {
      this.client.get<T>(url)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => observer.error(error));
    });
  }

  put<T>(url: string, data: any): Observable<T> {
    return new Observable((observer) => {
      this.client.put<T>(url, data)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => observer.error(error));
    });
  }

  post<T>(url: string, data: any): Observable<T> {
    return new Observable<T>((observer) => {
      this.client.post<T>(url, data)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => observer.error(error));
    });
  }

  delete<T>(url: string): Observable<T> {
    return new Observable<T>((observer) => {
      this.client.delete<T>(url)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => observer.error(error));
    });
  }
}

export default HttpClient;
