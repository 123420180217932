import { userSingleton } from "../../../classes/User";
import SuscriptionRepositoryImp from "../../../infrastructure/suscriptions/suscription-repository-implementation"; 

const repository = new SuscriptionRepositoryImp();

function updateSuscription(body) {  
    const suscription = repository.update(userSingleton.uid, userSingleton.cid, body).subscribe(
        (data) => {  
            console.log(data)
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()       
}

export { updateSuscription }