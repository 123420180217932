import { userSingleton } from "../classes/User"

export async function get(url) { 
    let res = await fetch(url, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*', 
        'x-uid': userSingleton.uid,
        'x-cid': userSingleton.cid
      },  
    }) 
    let out = await res.json() 
    return out
}
 
export async function post(url, data, isForm=false) { 
    var body = isForm ? data : JSON.stringify(data) 
    let res = await fetch(url, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*', 
        'x-uid': userSingleton.uid,
        'x-cid': userSingleton.cid
      }, 
      body: body, 
    })  
    if(res.status == 250){ 
      return 'limit_reached'
    }
    if (res.status < 300){   
      return res.json()  
    }
    if (res.status < 300){ 
      return res.json()  
    }
    if(res.status == 409){ 
      return 'conflict'
    }
    if(res.status == 599){ 
      return res.json()
    }
    return []
} 

export async function put(url, data) { 
  let res = await fetch(url, {
    method: 'PUT',
    headers: {
      'Access-Control-Allow-Origin': '*', 
      'x-uid': userSingleton.uid,
      'x-cid': userSingleton.cid
    }, 
    body: JSON.stringify(data), 
  }) 
  let out = await res.json()  
  return out
} 

export async function del(url) { 
  let res = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*', 
      'x-uid': userSingleton.uid,
      'x-cid': userSingleton.cid
    }
  }) 
  let out = await res.json()  
  return out
} 

export async function fileLoad(url, formData) {  
    let res = await fetch(url, {
      method: 'POST',
      headers: { 
        'Access-Control-Allow-Origin': '*', 
        'x-uid': userSingleton.uid,
        'x-cid': userSingleton.cid
      }, 
      body: formData,
    }) 
    let out = await res 
    return out
} 