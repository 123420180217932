import { Button, Form } from "react-bootstrap" 
import { useEffect, useState } from "react"
import { BiSave } from "react-icons/bi"
import DatePicker from "react-datepicker";
import { dateFormat } from "../../utils/helper";
import { post } from "../../utils/infra";
import { userSingleton } from "../../classes/User";
import Loading from "../../atomos/loading/loadingBtn/view";

function Resolution(params) {
    
    const [open, SetOpen] = useState(true)
    const [loading, SetLoading] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    
    useEffect(()=>{
        userSingleton.get().then((res) => {
            SetOpen(res.resolution != '')
        })   
    }, [])

    function save(e) {
        e.preventDefault()
        var aux = new Date()
        var data = {
            code: window.document.getElementById('resolution-resolution').value,
            from: window.document.getElementById('resolution-from').value,
            range: window.document.getElementById('resolution-range').value,
            date: dateFormat(startDate),
            created_at: dateFormat(aux)
        } 
        userSingleton.addResolution(data).then(()=>{
            SetLoading(false)
            SetOpen(true)
        })
    }

    return (
        <div className='modal-backgroud' hidden={open}>
            <div className='modal-box modal-inv-edit' style={{width:"500px", height:"500px"}}>
                <p style={{ "position": "absolute", "right": "0px", "top": "0px", "margin": "15px" }} onClick={() => [SetOpen(true)]}>X</p>
                <div className='inventario-box'>
                    <Form action="javascript:void(0);" onSubmit={(data) => [save(data)]}>
                        <Form.Group style={{ width: "95%" }}>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Cual es tu resolucion Pos ?</Form.Label>
                                <Form.Control id="resolution-resolution" type="text" placeholder='Resolucion pos' />
                            </Form.Group>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>En que valor inician tus pos de venta ?</Form.Label>
                                <Form.Control id="resolution-from" type="text" placeholder='Pos Inicial' />
                            </Form.Group> 
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Cuantos pos tiene tu resolucion ?</Form.Label>
                                <Form.Control id="resolution-range" type="text" placeholder='Rango pos' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Hasta cuando es valida la resolucion ?</Form.Label>
                                <DatePicker id="resolution-date" selected={startDate} onChange={(date) => [setStartDate(date)]} />
                            </Form.Group>
                        </Form.Group>
                        <Button className="save-btn" variant="primary" type="submit">
                            <Loading loading={loading}> <BiSave></BiSave> Guardar </Loading>
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Resolution