import { Observable } from 'rxjs';
import { Invoice, InvoicePDF } from '../invoice';
import { InvoiceInformation } from '../invoice-information';

export abstract class InvoiceRepository {
  abstract getAll(uid: string, cid: string | null): Observable<Invoice[]>;
  abstract isEnable(uid: string, cid: string | null): Observable<Invoice[]>;
  abstract getResolutions(uid: string, cid: string | null): Observable<string[]>;
  abstract getInvoiceInformation(uid: string, cid: string | null): Observable<InvoiceInformation>;
  abstract updateInvoiceInformation(uid: string, cid: string | null, data: any): Observable<InvoiceInformation>;
  abstract getPdf(uid: string, cid: string | null, cude: string): Observable<InvoicePDF>;
}

