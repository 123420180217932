import { userSingleton } from "../../../classes/User";
import ParameterizationRepositoryImp from "../../../infrastructure/parameterization/parameterization-repository-implementation"; 

const repository = new ParameterizationRepositoryImp();

function getAccounts(func) {  
    const suscription = repository.getAccounts(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => { 
            func(data) 
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()       
}

export { getAccounts }