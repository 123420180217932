//Custo css import
import './style.css'
import SellModule from '../../organismos/sellModule/view';
import PayMethods from '../../organismos/paymethods/view';
import ProductsTable from '../../products/organismos/productsTable/view';
import { useEffect, useState } from 'react';

//Classes
import { userSingleton } from "../../classes/User"
import Resolution from '../../organismos/resolution/resolution';

import ModalBtn from '../../moleculas/modalButton/hub/view';
import GeneralLayout from '../../templates/general/view';
import ButtonGroup from '../../moleculas/buttonGroup/view';
import ThirdsModal from '../../thirds/organismos/thirdsModal/view';

function Pos() {

    const [inventario, setInventario] = useState([]);
    const [terceros, setTerceros] = useState([]);

    const [carrito, setCarrito] = useState({});
    const [paymethod, setPaymethod] = useState(true);
    const [terceroaux, SetTerceroAux] = useState(null);
    const [paymethods, setPaymethods] = useState([]);
    const [bill, setBill] = useState([{ "bill_id": 0 }]);
    const screenWidth = window.innerWidth;

    useEffect(() => {
        if (!userSingleton.uid) {
            window.location.replace('/login')
        }
    }, [])

    useEffect(() => { setInventario(inventario) }, [carrito])
    useEffect(() => { setTerceros(terceros) }, [terceros])

    return (
        <GeneralLayout>
            <div className='pos'>
                <Resolution ></Resolution>
                <ButtonGroup>
                    <ThirdsModal
                        id="btn-group-third"
                        tercero={terceroaux}
                        setTerceros={setTerceros}
                        SetTerceroAux={SetTerceroAux}
                    ></ThirdsModal>
                    <ModalBtn
                        id="mobile-pay"
                        title="Pagar"
                        badge={Object.keys(carrito)?.length}
                        hidden={screenWidth > 600}
                    >
                        <SellModule
                            handlePaymethod={setPaymethod}
                            setCarrito={setCarrito}
                            paymethods={paymethods}
                            handlebill={setBill}
                            carrito={carrito}
                            terceros={terceros}
                            tercero={terceroaux}
                            bill={bill}
                        >
                            <PayMethods
                                handlePaymethods={setPaymethods}
                                handlePaymethod={setPaymethod}
                                handleClean={setCarrito}
                                handleBill={setBill}
                                payopen={paymethod}
                                tercero={terceroaux}
                                handleCleanTercero={SetTerceroAux}
                            >
                                <ThirdsModal
                                    id="mobile-third"
                                    tercero={terceroaux}
                                    setTerceros={setTerceros}
                                    SetTerceroAux={SetTerceroAux}
                                ></ThirdsModal>
                            </PayMethods>
                        </SellModule>
                    </ModalBtn>
                </ButtonGroup>
                <div className='pos-box'>
                    <div className='pos-inventario'>
                        <div className='vd-inventario' id="inventario">
                            <ProductsTable
                                forceLoadData={true}
                                width="60%"
                                setCarrito={setCarrito}
                                compras={true}
                                buy={true}
                                tableHeight="65vh"
                            ></ProductsTable>
                        </div>
                    </div>
                    <div className="pos-pago">
                        <SellModule
                            handlePaymethod={setPaymethod}
                            setCarrito={setCarrito}
                            paymethods={paymethods}
                            handlebill={setBill}
                            carrito={carrito}
                            terceros={terceros}
                            tercero={terceroaux}
                            bill={bill}
                        >
                            <PayMethods
                                handlePaymethods={setPaymethods}
                                handlePaymethod={setPaymethod}
                                handleClean={setCarrito}
                                handleBill={setBill}
                                payopen={paymethod}
                                tercero={terceroaux}
                                handleCleanTercero={SetTerceroAux}
                            >
                                <div className="buttonbox" style={{ width: "150px", marginTop: "-30px", marginBottom: "-30px" }}>
                                    <ThirdsModal
                                        id="web-third"
                                        tercero={terceroaux}
                                        setTerceros={setTerceros}
                                        SetTerceroAux={SetTerceroAux}
                                    ></ThirdsModal>

                                </div>

                            </PayMethods>
                        </SellModule>
                    </div>
                </div>
            </div>
        </GeneralLayout>
    )

}

export default Pos
