import { userSingleton } from "../../classes/User"
import { sesion } from "../../views/login/controller"

export function getData() { 
    return sesion()
}

export function locationState() {
    if (window.location.pathname.includes('contador')){
        return ['pos', 'Pos']
    }else{
        if(userSingleton.roles_id == 3){
            return ['pos', 'Pos']
        } else {
            return ['contador', 'Contador']
        }
    }
}