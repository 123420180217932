// import './style.css' 
import { Button } from 'react-bootstrap'
import LoadingBtn from '../../loading/loadingBtn/view';
import { useEffect, useState } from 'react';
import { dispatchCustomEvent } from '../../../utils/dispatch';
import "./style.css"

function ButtonHub(props) {

    function handleClick(){
        dispatchCustomEvent('modal'+props.modalID,[])
        if(props.onClick?props.onClick:false) {props.onClick()}
    }

    return (
        <Button
            id={props.id ? "modal-btn-hub-" + props.id : "modal-btn-hub"}
            aria-disabled={true}
            className='buttonHub'
            onClick={handleClick}
            type="button"
        >
            {props.title}
            {/* {
                <Badge bg="danger">{badge}</Badge>
            } */}
        </Button>
    )
}

export default ButtonHub
