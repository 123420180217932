//Custo css import
import './style.css'
import AccountingReports from '../../organismos/accountingReports/view';
import PlanCuentas from '../../organismos/planCuentas/view';
import Parameterization from '../../organismos/parameterization/view';
import { useUserAuth } from "../../../AuthContext";
import Button from 'react-bootstrap/Button';
import { AiOutlineLogin } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { userSingleton } from "../../classes/User"
import { deleteCookie } from '../../utils/helper';
import { MdPostAdd } from "react-icons/md";

import Account from '../../organismos/account/view';
import GeneralLayout from '../../templates/general/view';
import ProductsHub from '../../products/organismos/productsHub/view';

function AppHub(props) {
    const { logOut } = useUserAuth();
    const [profileData, SetProfileData] = useState(null);

    useEffect(() => {
        if (!userSingleton.uid) {
            window.location.replace('/login')
        }
    }, [])

    const receiveDataFromChild = (data) => {
        SetProfileData(data);
    };

    return (
        <GeneralLayout>
            <div className='grid-container'>
                <Account profileData={profileData} SetProfileData={receiveDataFromChild}></Account>
                <Button className='btn-acc-rep-modal' onClick={() => [window.location.replace('/pos')]}>
                    <MdPostAdd />
                    <p style={{ fontWeight: "bold" }}>POS</p>
                </Button>
                <Button className='btn-acc-rep-modal' onClick={() => [window.location.replace('/compras')]}>
                    <MdPostAdd />
                    <p style={{ fontWeight: "bold" }}>Compras</p>
                </Button>
                <Button className='btn-acc-rep-modal' onClick={() => [window.location.replace('/invoice')]}>
                    <MdPostAdd />
                    <p style={{ fontWeight: "bold" }}>Facturas</p>
                </Button>
                <ProductsHub btn={true}></ProductsHub>
                <AccountingReports></AccountingReports>
                <Parameterization></Parameterization>
                <PlanCuentas></PlanCuentas>
                <Button className='btn-acc-rep-modal' onClick={() => [logOut(), deleteCookie("data"), window.location.replace('/login')]}>
                    <AiOutlineLogin style={{ color: "rgb(238,110,158)" }}></AiOutlineLogin>
                    <p style={{ color: "rgb(238,110,158)", fontWeight: "bolder" }}>Cerrar Sesion</p>
                </Button>
            </div>
        </GeneralLayout>
    )

}

export default AppHub