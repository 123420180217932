import { getCookie } from "../utils/helper";
import { get } from "../utils/infra"; 
import { userSingleton } from "./User";

class Inventario {
    
    constructor() {
        this.uid = userSingleton.uid;  
    }  

    async get(){ 
        let res = await get(process.env.REACT_APP_URL_POS+'/v1/stock?uid='+this.uid) 
        if(res.length != undefined){
            res.map((itm) => {
                itm.skutmp =  itm.sku + itm.value
            })
            return res
        }else{
            let res = await this.get()
            return res
        }
    }
}  

function createInstance() {
    if (stockSingleton != null && stockSingleton instanceof stockSingleton) {
        return stockSingleton
    } else {
        var object = new Inventario();
        return object;
    }
} 

export var stockSingleton = createInstance() 