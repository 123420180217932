import { del, get, post } from "../../utils/infra"; 

export async function updateUserProfile() {
    var companyName = window.document.getElementById("profile-company-name").value
    var userName = window.document.getElementById("profile-user-name").value
    var phone = window.document.getElementById("profile-phone").value
    var address = window.document.getElementById("profile-address").value 
    var image = window.document.getElementById("avatar-image").src 
    var data = {
        "companyName": companyName,
        "name": userName,
        "phone": phone,
        "image": image, 
        "address": address
    }  
    let res = await post(process.env.REACT_APP_URL_POS+'/v1/profile', data)
    return res
}

export async function loadImage(image) {

    if (!image) {
        window.alert('No selecciono ninguna imagen');
        throw new Error('No image provided');
    } 
    const acceptedFormats = ['image/jpeg', 'image/png']; // Add more formats if needed
    if (!acceptedFormats.includes(image.type)) {
        window.alert('Formato no soportado. Porfavor cargue imagenes JPEG o PNG.');
        throw new Error('Unsupported image format. Please upload a JPEG or PNG image.');
    } 
    const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
    if (image.size > maxSizeInBytes) {
        window.alert('La imagen excede el tamaño maximo (1MB). Porfavor cargue una imagen mas pequeña.');
        throw new Error('Image size exceeds the maximum allowed size (1MB). Please upload a smaller image.');
    }

    const formData = new FormData()
    formData.append('image', image)
    try {
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/profile/image', formData, true)
        return res;
    } catch (error) { 
        throw new Error('Failed to upload image: ' + error.message);
    }
}

export async function handleUnsuscribe() {  
    if(window.confirm('Estas seguro de cancelar la suscripcion')){
        let res = await del(process.env.REACT_APP_URL_POS+'/v1/suscription')
        console.log(res)
        return res
    }
    else{
        return ""
    } 
}