//Custo css import
import './style.css'

//Boostrap import  
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Loading from '../../../atomos/loading/loadingBtn/view';

//React import 
import { useEffect, useState } from 'react';

//Controller import   
import { BiSave } from 'react-icons/bi';
import { AiOutlineUpload } from 'react-icons/ai';

//Widgets import  
//Classes
import { Producto } from "../../../classes/Producto";
import { carSingleton } from "../../../classes/Carrito";
import { userSingleton } from '../../../classes/User';
import { fileLoad } from '../../../utils/infra';
import ModalBtn from '../../../moleculas/modalButton/hub/view';


function LoadProductsFromFile(props) {
 
    var [loading, SetLoading] = useState(false)
    const [files, setFile] = useState(undefined)

    async function handleFile() {
        const formData = new FormData();
        formData.append(
            'file',
            files[0],
            files[0].name
        );
        await fetch(process.env.REACT_APP_URL_POS + '/v1/file/stock?uid=' + userSingleton.data.uid, {
            method: 'POST',
            body: formData,
        }).then((res) => [props.triggerInventario(res)]).catch(() => { window.alert("Error creando producto") });
    }

    return ( 
        <ModalBtn
            id="loadStockFromFile"
            title={<p><AiOutlineUpload></AiOutlineUpload> Cargar</p>}
        >
            <Form enctype="multipart/form-data"  action="javascript:void(0);" onSubmit={() => [SetLoading(true), handleFile().then(() => [SetLoading(false)])]}>
                    <a style={{ color: "blue" }} href="https://public-pos.s3.us-west-2.amazonaws.com/plantilla_pos_products.xlsx" action="javascript:void(0);" >Descarga la plantilla</a>
                    <hr></hr>
                    <Form.Control style={{ marginTop: "30px" }} multiple type="file" onChange={(e) => [e.preventDefault(), setFile(e.target.files)]} />
                    <br></br>
                    <Button className='save-btn' variant="primary" type="submit" style={{ width: "100%" }}>
                        <Loading loading={loading}><BiSave></BiSave> Enviar Archivo</Loading>
                    </Button>
                </Form>
        </ModalBtn>
    )
}

export default LoadProductsFromFile
