import UserRepositoryImp from '../../../infrastructure/user/user-repository-implementation'
import { createNewUser } from '../thirdsTable/controller'
import ModalBtn from '../../../moleculas/modalButton/hub/view';
import Loading from '../../../atomos/loading/loadingBtn/view';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BiSave } from 'react-icons/bi';
import { useState } from 'react';
import './style.css' 
import { dispatchCustomEvent } from '../../../utils/dispatch';

function NewThird(props) {

    const userRepository = new UserRepositoryImp();

    const [loading, SetLoading] = useState(false)
    const [open, SetOpen] = useState(true)
    const [idType, SetIdType] = useState([]) 
    const [third, SetThird] = useState({})

    function handleSave(res) {
        SetLoading(false)
        if (res["status"] === false) {
            window.alert(res['message'])
        } else {
            window.alert(res['message']) 
            var allInputs = document.querySelectorAll('input');
            allInputs.forEach(singleInput => singleInput.value = '');
            SetLoading(false)
        }
    }

    function handleClick() {
        const thirdTypeSuscription = userRepository.listIdTypes().subscribe(
            (data) => {
                SetIdType(data) 
            },
            (error) => {
                console.error(error)
            }            
        ) 
        return () => thirdTypeSuscription.unsubscribe()
    }

    function handleChange(e) {
        e.preventDefault() 
        var aux = third
        var key = e.target.id.replace("third-", "")
        aux[key] = e.target.value
        SetThird(aux)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        var data = third
        const existeIdentificationsId = "identifications_id" in data;
        if(!existeIdentificationsId) {
            data["identifications_id"] = "1"
        } 
        SetLoading(true)
        createNewUser(data, props.type ? props.type : "client").then((res) => {
            handleSave(res)
            dispatchCustomEvent("update-thirds", [])
            dispatchCustomEvent("close-new-third-modal", [])
        })
    }

    return (
        <div>
            <ModalBtn
                id={props.id ? "new-third-"+props.id : "new-third"}
                title={props.text ? props.text : "Nuevo cliente"}
                onClick={handleClick}
            >
                <div className='inventario-box' style={{ marginTop: "15px" }}>
                    <Form action="javascript:void(0);" id="third-form" onChange={handleChange}>
                        <Form.Group >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control id="third-name"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Email</Form.Label>
                            <Form.Control id="third-email"></Form.Control>
                        </Form.Group>
                        <div style={{ display: "flex" }}>
                            <Form.Group >
                                <Form.Label>Documento</Form.Label>
                                <Form.Control id="third-identification"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select id="third-identifications_id" >
                                    {
                                        idType ?
                                            idType.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.type}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <Form.Group >
                            <Form.Label>Telefono</Form.Label>
                            <Form.Control id="third-phone"></Form.Control>
                        </Form.Group> 
                        <Button 
                            onClick={(e) => [handleSubmit(e)]}
                            style={{ width: "96%", margin:"15px" }} variant="primary" type="submit">
                            <Loading loading={loading}> <BiSave></BiSave> Guardar </Loading>
                        </Button>
                    </Form>
                </div>
            </ModalBtn> 
        </div>
    )
}

export default NewThird