import { SuscriptionRepository } from '../../domain/models/suscriptions/gateway/suscriptions-repository';
import { Plan } from '../../domain/models/suscriptions/suscriptions';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class SuscriptionRepositoryImp extends SuscriptionRepository {
  getAllPlans(uid: string, cid: string | null): Observable<Plan[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Plan[]>(`suscription/plans`);
  }
  
  get(uid: string, cid: string | null): Observable<Plan> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Plan>(`suscription`);
  }

  cancel(uid: string, cid: string | null): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.delete<any>(`suscription`);
  }

  suscribe(uid: string, cid: string | null, body : any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post<any>(`suscription`, body);
  }

  update(uid: string, cid: string | null, body : any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.put<any>(`suscription`, body);
  }
     
}