import { userSingleton } from "../../../classes/User";
import ProductsRepositoryImp from "../../../infrastructure/products/products-repository-implementation"; 

const productRepository = new ProductsRepositoryImp();

function getPayMethods(func) {  
    const payMethodsSuscription = productRepository.getPayMethods(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => { 
            func(data) 
        },
        (error) => {
            console.error(error)
        }
    )
    return () => payMethodsSuscription.unsubscribe()       
}

export { getPayMethods }