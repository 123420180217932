import { get, post } from "../../utils/infra";
import { userSingleton } from "../../classes/User"
import { setLocalStorage } from "../../utils/helper";
import { getLocalStorage } from "../../utils/helper";

export async function getAccounts() { 
    let res = getLocalStorage('cuentas')

    if (res.length == undefined){
        res = await get(process.env.REACT_APP_URL_POS+'/v1/accounts-plan')  
        setLocalStorage('cuentas', res)
    }
    
    return res
}