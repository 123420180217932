// import './style.css' 
import { Button } from 'react-bootstrap'  
import { dispatchCustomEvent } from '../../../utils/dispatch';
import "./style.css"
import { useEffect, useState } from 'react';
import LoadingBtn from '../../loading/loadingBtn/view';

function ButtonLoading(props) {

    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        window.addEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent); 
        return () => {
            window.removeEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent); 
        };
    }, [])

    function handleEvent() {
        setLoading(false)
    }

    function handleClick(){  
        if(props.onClick?props.onClick:false) {props.onClick()}
        if(props.showLoading?props.showLoading:false) {setLoading(props.showLoading)}
    }

    return (
        <Button
            id={props.id ? props.id : "btn-loading"}
            aria-disabled={true} 
            className='buttonLoading'
            onClick={handleClick}
            type="button"
        >
            <LoadingBtn loading={loading}>
                {props.children}
                {props.title}
            </LoadingBtn>  
        </Button>
    )

}

export default ButtonLoading
