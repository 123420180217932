//Custo css import
import './style.css'

//Boostrap import   
import { useEffect, useState } from 'react';
import { carSingleton } from "../../classes/Carrito"
import { userSingleton } from "../../classes/User"
import { dispatchCustomEvent } from '../../utils/dispatch';
import { ButtonBasic } from '../../atomos/buttons';
import TaxRepositoryImp from '../../infrastructure/taxes/tax-repository-implementation'

function SellModule(props) {

    const [selltaxeskeys, SetSellTaxesKeys] = useState([])
    const [carritoKeys, SetCarritoKeys] = useState([]); 
    const [selltaxes, SetSellTaxes] = useState([])
    const [carrito, SetCarrito] = useState([]);
    const [tercero, SetTercero] = useState(undefined); 
    const [total, SetTotal] = useState(0);
    const [taxes, SetTaxes] = useState(null) 
    var vdinput;

    const taxRepository = new TaxRepositoryImp();
 
    function handleTax() {  
        const taxSuscription = taxRepository.get(userSingleton.uid, userSingleton.cid, "sell").subscribe(
            (data) => { 
                SetTaxes(data) 
            },
            (error) => {
                console.log(error)
            }
        )
        return () => taxSuscription.unsubscribe()       
    }
  
    useEffect(() => { 
        if(taxes == null){
            handleTax()
        }
        var tax_sumary = carSingleton.getTaxSumary()
        var car_items = carSingleton.getCar()
        SetSellTaxesKeys(Object.keys(tax_sumary))
        SetCarritoKeys(Object.keys(car_items))
        SetSellTaxes(tax_sumary)
        SetCarrito(car_items)
        if (Object.keys(car_items).length > 0) {
            var tot = 0
            Object.keys(car_items).map((itm) => {
                tot = tot + car_items[itm].subtotal
                SetTotal(tot)
                carSingleton.setTotal(tot)
            }) 
        }
    }, [props.carrito])

    useEffect(() => {SetTercero(props.tercero)}, [props.tercero]) 

    function clean() {
        carSingleton.clean()
        SetCarritoKeys([])
        SetCarrito([])
        SetSellTaxesKeys([])
        SetSellTaxes([])
        SetTercero(undefined)
        SetTotal(0)
        dispatchCustomEvent("close-all-modal", [])
    }

    function updateSellItemPrice(item) {
        let cnt = prompt("Precio:", 0);
        var aux = carSingleton.setProductPrice(item["skutmp"], parseInt(cnt))
        props.setCarrito(aux)
    }

    function updateSellItem(item) {
        let cnt = prompt("Unidades:", 0);
        if (carrito[item["skutmp"]]["count"] < cnt) {
            cnt = Array.from(Array(parseInt(cnt - carrito[item["skutmp"]]["count"])).keys())
            cnt.map(() => {
                var aux = carSingleton.add(item)
                props.setCarrito(aux)
            })
        } else {
            cnt = Array.from(Array(parseInt(carrito[item["skutmp"]]["count"] - cnt)).keys())
            cnt.map(() => {
                var aux = carSingleton.remove(item)
                props.setCarrito(aux)
            })
        }
    } 
    
    return (
        <div className='box'> 
            <div>
                 {
                    tercero ?
                    <div style={{lineHeight:"5px", fontSize:"12px", marginLeft:"5px", marginTop:"5px"}}>  
                        <p><span style={{fontWeight:"bold"}}>Nombre:</span> {tercero.name}</p>
                        <p><span style={{fontWeight:"bold"}}>Documento:</span> {tercero.identification}</p>
                        <p><span style={{fontWeight:"bold"}}>Telefono:</span> {tercero.phone}</p>
                    </div>: <div></div>
                 }
            </div>

            <div className='compras-productos'>
                <table className="table" style={{ "fontSize": "15px" }}>
                    <thead>
                        <tr >
                            <th scope="col">Nombre</th>
                            <th scope="col">Unitario</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">SubTotal</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            carritoKeys.map(item => {
                                return (
                                    <tr >
                                        <td>{carrito[item] ? carrito[item][0].name : "Null"}</td>
                                        <td onClick={() => [updateSellItemPrice(carrito[item][0])]}>{carrito[item] ? carrito[item][0].value : 0}</td>
                                        <td onClick={() => [updateSellItem(carrito[item][0])]}>{carrito[item].count ? carrito[item].count : 0}</td>
                                        <td>{carrito[item] ? carrito[item].subtotal : 0}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <hr style={{width:"90%"}}></hr>

            <div className='compras-taxes'>
                <table class="table" style={{ "fontSize": "15px" }}>
                    <thead>
                        <tr>
                            <th scope="col">Impuesto</th>
                            <th scope="col">Valor</th>
                            <th scope="col">SubTotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selltaxeskeys ?
                            selltaxeskeys.map(tax => {  
                                return (
                                    <tr hidden={selltaxes[tax].tax_subtotal ? false : true}>
                                        <td>{Array.isArray(taxes) ? (taxes.filter(i => i.id == tax)[0]?.description) : "Null"}</td>
                                        <td>{selltaxes ? selltaxes[tax].sell_tax : 0}</td>
                                        <td>{selltaxes ? Math.round(selltaxes[tax].tax_subtotal) : 0}</td>
                                    </tr>
                                )
                            }) : <div></div>
                        }
                    </tbody>
                </table>
            </div>
            <div className='titlebox'>
                <p>Total</p>
                <p>{Math.round(total)}</p>
            </div>
            <hr style={{width:"90%"}}></hr>
            <div className='sell-button-box'>
                <ButtonBasic onClick={() => [clean()]}>Cancelar</ButtonBasic>
                {props.children}
            </div>
        </div>
    )

}

export default SellModule