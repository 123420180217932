type Product = {
    id : number,
    sku  : string,
    name : string,
    value : number, 
    buy_tax : number,
    sell_tax : number, 
    sell_taxes_id : number,
}

type ProductOrder = {
    billID: number, 
    thirdID: number, 
    buyDate: string, 
    vencimiento: string, 
    totalPay: number, 
    retention: number,
}; 

type PayMethods = {
    id: number,
    method: string,
}

class ProductFactory {
    public static create(
        id : number,
        sku  : string,
        name : string,
        value : number, 
        buy_tax : number,
        sell_tax : number, 
        sell_taxes_id : number,
    ): Product {
        return {
            id ,
            sku  ,
            name ,
            value , 
            buy_tax ,
            sell_tax , 
            sell_taxes_id ,
        };
    }
} 

export { Product, ProductOrder, ProductFactory, PayMethods};