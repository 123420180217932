//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Loading from '../../atomos/loading/loadingBtn/view';

//React import
import { useEffect, useState } from 'react';

//Controller import
import { BiSave } from 'react-icons/bi';

//Widgets import
//Classes
import { carSingleton } from "../../classes/Carrito";
import { userSingleton } from '../../classes/User';
import { lang } from '../../utils/langs';

import { handleSubTotal } from "./controller"
import { useFeatureFlag } from 'configcat-react';
import ModalBtn from '../../moleculas/modalButton/hub/view';
import DatePicker from "react-datepicker";
import TaxRepositoryImp from '../../infrastructure/taxes/tax-repository-implementation'
import InvoiceRepositoryImp from '../../infrastructure/invoice/invoice-repository-implementation';

function PayMethods(props) {

    const [close, SetClose] = useState(false)
    const [buyDate, setBuyDate] = useState(new Date());
    const [totalToPay, SetTotalToPay] = useState(carSingleton.getTotal())
    const [enableFlag, SetEnableFlag] = useState(true)
    var [paymethods, SetPayMethods] = useState([])
    var [cambio, SetCambio] = useState(0)
    var [loading, SetLoading] = useState(false)
    var [isPayDisable, SetIsPayDisable] = useState(true)
    var [isInvoiceDisable, SetIsInoviceDisable] = useState(true)
    var [generateInvoice, SetGenerateInvoice] = useState(false)
    var [needEDocument, SetNeedEDocument] = useState(false)
    var [selectedMethods, SetSelectedMethods] = useState([])
    var [retentions, SetRetentions] = useState([])

    const { value: invoiceLowAmountValue, loading: invoiceLowAmountLoading } = useFeatureFlag("invoice_low_amount", 235325);

    useEffect(() => {
        handleFormChage()
    }, [selectedMethods, props.tercero, generateInvoice])

    const taxRepository = new TaxRepositoryImp();
    const invoiceRepository = new InvoiceRepositoryImp();

    function handleRetention(type) {
        const taxSuscription = taxRepository.get(userSingleton.uid, userSingleton.cid, "retention").subscribe(
            (data) => {
                SetRetentions(data)
            },
            (error) => {
                console.log(error)
            }
        )
        return () => taxSuscription.unsubscribe()
    }

    async function handlePay(data) {
        data.preventDefault()
        SetLoading(true)
        var aux = selectedMethods.map((id) => {
            var elementValue = window.document.getElementById("pay-" + id).value
            if (id == '3' && cambio < 0) {
                elementValue = totalToPay
            }
            return { "id": id, "value": elementValue }
        })
        carSingleton.setMethod(aux)
        props.handlePaymethods(aux)

        if (props.type == "buy") {
            carSingleton.createPreOrder(
                props.tercero,
                window.document.getElementById("expiration-days")?.value,
                buyDate,
                window.document.getElementById("retention-compras").value
            ).then(() => {
                handleClose()
            })
        } else {
            carSingleton.pagar(props.tercero, generateInvoice).then((res) => {
                if (res["status"]) {
                    props.handleBill(res["data"])
                    window.alert("Venta guardada")
                    handleClose()
                } else {
                    var messages = {
                        "limit_reached": "Ya alcanzaste el limite de ventas de este mes, cambia tu plan y vende sin limites!!"
                    }
                    window.alert(messages[res["message"]])
                    SetLoading(false)
                }
            })
        }
        return 'ok'
    }

    function PrintElem() {
        var toPrint = window.document.getElementById('bill-sell-module')
        var mywindow = window.open('', 'PRINT');
        mywindow.document.write(toPrint.innerHTML);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.onfocus = function () {
            mywindow.close()
            handleClose()
        }
        return true;
    }

    function isEnable() {
        const isEnableSuscription = invoiceRepository.isEnable(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                if ("electronic_invoice" in data && data["electronic_invoice"] == 1) {
                    SetIsInoviceDisable(false)
                }
                else {
                    SetIsInoviceDisable(true)
                }
                SetEnableFlag(false)
            },
            (error) => {
                console.error('Error fetching invoiceInfoSuscription:', error);
            }
        )
        return () => {
            isEnableSuscription.unsubscribe()
        }
    }

    function handleFormChage() {
        if (enableFlag) {
            isEnable()
        }
        if (props.type == "buy") {
            handleRetention()
        }
        userSingleton.payMethods().then((res) => {
            SetPayMethods(res)
        })
        if (totalToPay >= invoiceLowAmountValue) {
            SetNeedEDocument(true)
        }
        SetTotalToPay(carSingleton.getTotal())
        var handleSub = handleSubTotal(selectedMethods)
        SetCambio(handleSub[0] ? handleSub[0] : 0)
        IsPayDisable(handleSub)
    }

    const emitCustomEvent = () => {
        console.log(props.id)
        const event = new CustomEvent('close-all-modal', null);
        window.dispatchEvent(event);
    };

    function handleClose() {
        carSingleton.clean()
        props.handleCleanTercero(undefined)

        SetLoading(false)
        SetCambio(0)
        SetSelectedMethods([])
        props.handleClean([])

        emitCustomEvent()
        SetClose(true)
    }

    function IsPayDisable(handleSub) {

        var payed = false
        var needThird = generateInvoice
        var haveThird = props.tercero ? true : false

        if (handleSub[1] <= 0) {
            payed = true
        }

        if (props.type == "buy") {
            needThird = true
        }

        if (payed) {
            if (needThird && haveThird) {
                SetIsPayDisable(false)
            } else {
                if (!needThird) {
                    SetIsPayDisable(false)
                } else {
                    SetIsPayDisable(true)
                }
            }
        } else {
            SetIsPayDisable(true)
        }
    }


    return (
        <ModalBtn
            id="paymethods"
            title="Pagar"
            onClick={handleFormChage}
            onClose={handleClose}
            close={close}
            closeSet={SetClose}
        >
            <div className='titles-pay-box'>
                <p className="titles-pay" style={{ color: "black" }}>${totalToPay}</p>
                <p className="titles-cambio">Cambio ${cambio * -1}</p>
            </div>

            <div className='third-box'>
                {
                    props.tercero ?
                        <div style={{ display: "flex" }}>
                            <div style={{ marginLeft: "20px" }}>
                                <p><strong>Nombre:</strong> {props.tercero.name}</p>
                                <p><strong>Doc:</strong> {props.tercero.identification}</p>
                            </div>
                            <div style={{ marginLeft: "20px" }}>
                                <p><strong>Email:</strong> {props.tercero.email}</p>
                                <p><strong>Telefono:</strong>{props.tercero.phone}</p>
                            </div>
                        </div>

                        : <div></div>
                }
            </div>

            {
                props.type === 'buy' && (
                    <Form.Group id="buy-data-form" className='buy-data-form'>
                        <Form.Group>
                            <Form.Label>Retencion:</Form.Label>
                            <Form.Select style={{ width: "100%" }} id="retention-compras" >
                                <option value={0}>Ninguno</option>
                                {
                                    retentions ?
                                        retentions?.map((item) => {
                                            return (
                                                <option value={item?.value}>{item?.description}</option>
                                            )
                                        }) : <div></div>
                                }
                            </Form.Select>
                        </Form.Group>
                        <div className='group-child-buy'>
                            <Form.Group>
                                <Form.Label>Fecha de compra</Form.Label>
                                <DatePicker selected={buyDate} onChange={(date) => [setBuyDate(date)]}></DatePicker>
                            </Form.Group>
                            <div className='child-buy'>
                                {props.children}
                            </div>
                        </div>
                    </Form.Group>
                )
            }

            <Form id="paymethod-form" action="javascript:void(0);"
                onSubmit={(data) => [handlePay(data)]}
                onChange={() => [handleFormChage()]}
            >
                <Form.Group style={{ marginTop: "30px", width: "96%" }}>
                    <Form.Label>Methodos de pago:</Form.Label>
                    <Form.Select
                        id="select-pay-methods"
                        onChange={(e) => [paymethods.filter(i => i.id == e.target.value)[0] ? SetSelectedMethods([...selectedMethods, e.target.value]) : ""]}
                    >
                        <option value="---">---</option>
                        {
                            paymethods.map((method) => {
                                return (
                                    <option value={method.id}>{lang("es", method.method)}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" style={{ "width": "100%" }}>
                    <div name="color" id="color" style={{ minHeight: "130px", maxHeight: "130px", overflowY: "scroll", marginTop: "10px" }}>
                        {
                            selectedMethods ?
                                selectedMethods.map((id) => {
                                    return (
                                        <Form.Group className="mb-3" style={{ "width": "95%", "margin": "5px" }}>
                                            <Form.Label>{lang("es", paymethods.filter(i => i.id == id)[0].method)}:</Form.Label>
                                            <div style={{ display: "flex", maxHeight: "40px" }}>
                                                <Form.Control style={{ width: "90%", marginRight: "5px" }} id={'pay-' + id} type="text" placeholder="valor" />
                                                {
                                                    id == 4 && (
                                                        <Form.Group style={{ width: "40%", maxHeight: "50px", marginTop: "-32px" }}>
                                                            <Form.Label>Vencimiento (dias)</Form.Label>
                                                            <Form.Control style={{ width: "90%" }} id="expiration-days"></Form.Control>
                                                        </Form.Group>
                                                    )
                                                }
                                                <Button onClick={() => [SetSelectedMethods(selectedMethods.filter(i => i != id))]}>X</Button>
                                            </div>
                                        </Form.Group>
                                    )
                                }) : <div></div>
                        }
                    </div>
                </Form.Group>
                {
                    props.type != "buy" && (
                        <Form.Group className="mb-3" style={{ "width": "100%", "margin": "1px" }}>
                            <div className='control-box'>
                                <Form.Check
                                    type="checkbox"
                                    style={{ width: "100%", color: "black" }}
                                    id="generate-electronic-bill"
                                    label="Generar factura electronica"
                                    onChange={(e) => [SetGenerateInvoice(e.target.checked)]}
                                    onClick={(e) => [handleFormChage()]}
                                    disabled={isInvoiceDisable}
                                ></Form.Check>
                                {props.children}
                            </div>
                        </Form.Group>
                    )
                }
                <Button disabled={isPayDisable} variant="primary" type="submit" style={{ width: "96%", margin: "3%" }}>
                    {
                        props.type === "buy" && (
                            <Loading loading={loading}> <BiSave></BiSave> Crear compra </Loading>
                        )
                    }
                    {
                        props.type != "buy" && (
                            <Loading loading={loading}> <BiSave></BiSave> {needEDocument ? "La venta supera el valor minimo para factura electronica" : "Pagar"} </Loading>
                        )
                    }
                </Button>
            </Form>
        </ModalBtn>
    )
}

export default PayMethods
