import { userSingleton } from "../../../classes/User";
import SuscriptionRepositoryImp from "../../../infrastructure/suscriptions/suscription-repository-implementation"; 

const repository = new SuscriptionRepositoryImp();

function cancelSuscription() {  
    const suscription = repository.cancel(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => { console.log(data) },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()       
}

export { cancelSuscription }