import { userSingleton } from "../../../classes/User";
import UserRepositoryImp from "../../../infrastructure/user/user-repository-implementation"; 

const repository = new UserRepositoryImp();

function getProfile(func) {  
    const suscription = repository.get(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => { 
            func(data) 
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()       
}

export { getProfile }