//Custo css import
import './style.css'

//Boostrap import 
import Button from 'react-bootstrap/Button'; 
import { VscDebugContinueSmall } from 'react-icons/vsc';
import { AiOutlineCheck } from 'react-icons/ai';


//Controller import 
import { enviarMensaje } from './controller'
import { useEffect, useState } from "react"; 
import { getPlans } from "../../domain/usecase/suscriptions/get-all-plans";

function Landing(props) {

    const [plans, SetPlans] = useState([])

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname == "/"){
            getPlans(SetPlans)
        }
    }, [])

    return (
        <div style={{ overflow: "hidden" }}>
            <div className="land-top-bar">
                <h1># Easypos</h1>
                <div className="land-btn-group">
                    <Button className="land-log-btn land-login-btn" href="/login">Iniciar sesion</Button>
                    <Button className="land-log-btn" href="/register">Registrarse</Button>
                </div>
            </div>
            <div className="land-g1">
                <div className="land-info">
                    <h2 style={{ fontSize: "45px", fontWeight: "bolder" }}>Gestiona y potencia tu negocio</h2>
                    <p style={{ fontSize: "25px" }}>Vende donde quieras. Analiza tus ventas cuando quieras.</p>
                    <p style={{ fontSize: "20px", marginTop: "30px" }}>¿Quieres cuentas ordenadas ya?</p>
                    <div style={{ height: "100px" }}>
                        <div style={{ height: "25%" }}>
                            <Button style={{ width: "350px" }} onClick={() => [enviarMensaje("Me gustaria una demostracion de easypos...")]} className="land-start-btn" >{"Solicitar una demo"}<VscDebugContinueSmall></VscDebugContinueSmall></Button>
                        </div>
                        <p style={{ fontSize: "20px", marginTop: "30px" }}>de nuestro software.</p>
                    </div>
                </div>
                <div className="image-box">
                    <img className="image-1" src="https://public-pos.s3.us-west-2.amazonaws.com/tmp/Untitled_Artwork+1.png"></img>
                </div>
            </div>
            <div className="land-g2" >
                <div style={{ width: "100%" }}>
                    <img style={{ width: "100%" }} src="https://public-pos.s3.us-west-2.amazonaws.com/tmp/Untitled_Artwork+3.png"></img>
                </div>
                <p style={{ fontSize: "30px", fontWeight: "bold", margin: "30px" }}>Gestiona tu empresa  de una manera sencilla y eficiente.</p>
                <p style={{ fontSize: "20px" }}>Vende donde quieras. Analiza tus ventas cuando quieras.</p>
                <div className="container-box">
                    <div className="land-pagos">
                        <div style={{ height: "30%" }}>
                            <p className="land-plan-title">EasyPos</p>
                            <p className="land-plan-description"> Administra clientes, productos y ventas fácilmente.</p>
                        </div>
                        <hr style={{ margin: "5px", borderWidth: "1px" }}></hr>
                        <div style={{ height: "45%", padding: "10px" }}>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Punto de venta</p>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Gestion de clientes</p>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Control de inventario</p>

                        </div>
                        <Empezar text="Saber mas..." msg="Quiero mas informacion"></Empezar>
                    </div>
                    <div className="land-pagos">
                        <div style={{ height: "30%" }}>
                            <p className="land-plan-title">EasyDocs</p>
                            <p className="land-plan-description"> Genera y administra documentos electronicos.</p>
                        </div>
                        <hr style={{ margin: "5px", borderWidth: "1px" }}></hr>
                        <div style={{ height: "45%", padding: "10px" }}>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Facturacion Electronica</p>
                        </div>
                        <Empezar text="Saber mas..." msg="Quiero mas informacion"></Empezar>
                    </div>
                    <div className="land-pagos">
                        <div style={{ height: "30%" }}>
                            <p className="land-plan-title">EasyData</p>
                            <p className="land-plan-description"> Analiza la informacion de tu negocio cuando quieras.</p>
                        </div>
                        <hr style={{ margin: "5px", borderWidth: "1px" }}></hr>
                        <div style={{ height: "45%", padding: "10px" }}>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Dashboard de compras y ventas</p>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Analitica de clientes frecuentes</p>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Reporteria</p>
                            <p className="land-plan-item"> <AiOutlineCheck></AiOutlineCheck> Plan de cuentas</p>
                        </div>
                        <Empezar text="Saber mas..." msg="Quiero mas informacion"></Empezar>
                    </div>
                </div>

            </div>
            <div className="land-g3">
                <p style={{ fontSize: "50px", fontWeight: "bold", margin: "30px" }}>Precios</p>
                <div className="container-box">
                    {
                        plans ?
                            plans?.map((plan) => {
                                return (
                                    <div className="land-pagos">
                                        <div style={{ height: "25%" }}>
                                            <p className="land-plan-title">{plan.name?.charAt(0).toUpperCase() + plan.name?.slice(1)}</p>
                                            <p className="land-plan-description">{plan?.price > 0 && ("Por " + plan?.price + " COP al mes")}</p>
                                            <p className="land-plan-description">{plan?.price == 0 && ("Sin cargos menuales")}</p>
                                        </div>
                                        <hr style={{ margin: "5px", borderWidth: "1px" }}></hr>
                                        <div style={{ height: "45%", padding: "10px" }}>
                                            {
                                                plan.information.data?.map((item) => {
                                                    return (
                                                        <p className="land-plan-item"> <AiOutlineCheck /> {item}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div style={{ height: "25%" }}>
                                            <Empezar msg="Quiero una demo"></Empezar>
                                            {/* <Button style={props.style} onClick={() => [SetSelectedPlan([plan]), handleNext(plan)]} className="land-start-btn" >{props.text ? props.text : "Seleccionar"}<VscDebugContinueSmall></VscDebugContinueSmall></Button> */}
                                        </div>
                                    </div>
                                )
                            }) : <div></div>
                    }
                </div>

                <p>** Con limite de clientes, ventas y productos</p>
            </div>
        </div>
    )

}

function Empezar(props) {
    return (
        <div style={{ height: "25%" }}>
            <Button style={props.style} onClick={() => [window.location.replace("register")]} className="land-start-btn" >{props.text ? props.text : "Empezar"}<VscDebugContinueSmall></VscDebugContinueSmall></Button>
        </div>
    )
}

export default Landing