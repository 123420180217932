//Custo css import
import './style.css'
import SellModule from '../../organismos/sellModule/view';
import ThirdsModal from '../../thirds/organismos/thirdsModal/view';
import ProductsTable from '../../products/organismos/productsTable/view';

//React import
import { useEffect, useState } from 'react';

//Classes
import { userSingleton } from "../../classes/User"
import { useUserRole } from "../../../RoleContext";
import PayMethods from '../../organismos/paymethods/view';

import { getOrdersByBill } from './controller'
import { carSingleton } from '../../classes/Carrito';
import GeneralLayout from '../../templates/general/view';
import ButtonGroup from '../../moleculas/buttonGroup/view';
import PreorderTable from '../../preorders/preorderTable/view';

function Compras(props) {

    const { role } = useUserRole();

    const [inventario, setInventario] = useState([]);
    const [terceros, setTerceros] = useState([]);
    const [preorder, setPreOrder] = useState(undefined);

    const [loading, SetLoading] = useState(false);
    const [carrito, setCarrito] = useState({});
    const [paymethod, setPaymethod] = useState(true);
    const [tercero, SetTercero] = useState(true);
    const [terceroaux, SetTerceroAux] = useState(null);
    const [paymethods, setPaymethods] = useState([]);
    const [bill, setBill] = useState([{ "bill_id": 0 }]);
    const [toClean, setToClean] = useState([])
    const [orders, setOrders] = useState(undefined)

    useEffect(() => {
        if (!userSingleton.uid) {
            window.location.replace('/login')
        }
    }, [])

    useEffect(() => { setInventario(inventario) }, [carrito])
    useEffect(() => { setTerceros(terceros) }, [terceros])

    function handleCompra(item) {
        getOrdersByBill(item.bill_id).then((res) => {
            setPreOrder(res)
        })
    }

    function handleChange(item) {
        item.preventDefault()
        setToClean([...toClean, item.target.id])
        var aux = item.target.id.split('-')
        var itm = preorder.map(key => {
            if (key.sku == aux[0]) {
                if ("amount" == aux[1]) {
                    key.amount = parseFloat(item.target.value)
                }
                if ("value" == aux[1]) {
                    key.value = parseFloat(item.target.value)
                }
            }
            return key
        })
        setPreOrder(itm)
    }

    function handleCloseModal() {
        setPreOrder(undefined)
        toClean.map((id) => {
            var element = window.document.getElementById(id)
            element.value = ''
        })
        setToClean([])
    }

    function handleComprar() {
        var document = window.document.getElementById('buy-document')?.value
        carSingleton.comprar(preorder, document ? document : "").then((res) => {
            setOrders(res)
            handleCloseModal()
            setPreOrder([])
        })
    }

    return (
        <GeneralLayout>
            <div className='compras'>
                <div className='pos-inventario'>
                    <ButtonGroup>
                        <ThirdsModal
                            id="mobile-third"
                            tercero={terceroaux}
                            setTerceros={setTerceros}
                            SetTerceroAux={SetTerceroAux}
                            text="Proveedor"
                            type="provider"
                        />
                        {/* <PreorderTable></PreorderTable> */}
                    </ButtonGroup>

                    <div className='vd-inventario'>
                        <ProductsTable
                            width="60%"
                            forceLoadData={true}
                            setCarrito={setCarrito}
                            trigger={inventario}
                            compras={true}
                            buy={true}
                            tableHeight="65vh"
                        ></ProductsTable>
                    </div>
                </div>
                <div className="pos-pago">
                    <SellModule
                        handlePaymethod={setPaymethod}
                        setCarrito={setCarrito}
                        SetTercero={SetTercero}
                        paymethods={paymethods}
                        handlebill={setBill}
                        carrito={carrito}
                        terceros={terceros}
                        tercero={terceroaux}
                        bill={bill}
                        type="buy"
                    >
                        <PayMethods
                            handlePaymethods={setPaymethods}
                            handlePaymethod={setPaymethod}
                            handleClean={setCarrito}
                            handleBill={setBill}
                            payopen={paymethod}
                            tercero={terceroaux}
                            handleCleanTercero={SetTerceroAux}
                            type="buy"
                        >
                            <ThirdsModal
                                id="web-third"
                                tercero={terceroaux}
                                setTerceros={setTerceros}
                                SetTerceroAux={SetTerceroAux}
                                text="Proveedor"
                                type="provider"
                            ></ThirdsModal>
                        </PayMethods>
                    </SellModule>
                </div>
            </div>
        </GeneralLayout>
    )

}

export default Compras
