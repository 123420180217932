//Custo css import
import './style.css'
import GeneralLayout from '../../templates/general/view'
import Table from '../../moleculas/tablas/view'
import Tab from '../../moleculas/tab/view'
import { useEffect, useState } from 'react'
import { userSingleton } from '../../classes/User'
import { createPdfBlob, createPdfUrl, enviarMensaje } from './controller'
import ButtonAction from '../../atomos/buttons/buttonAction/view'
import { dispatchCustomEvent } from '../../utils/dispatch'
import ButtonGroup from '../../moleculas/buttonGroup/view'
import InvoiceRepositoryImp from '../../infrastructure/invoice/invoice-repository-implementation'
import InvoiceDataForm from '../../invoices/invoiceDataForm/view'
import { EmptyArray } from '../../domain/models/empty/empty'

function Invoice(props) {

    const invoiceRepository = new InvoiceRepositoryImp();

    const tabs = [
        { title: "Facturas", eventKey: "invoices" },
        { title: "Configuracion", eventKey: "settings" }
    ]
    const [tab, setTab] = useState("invoices")
    const [invoices, SetInvoices] = useState(Array.from([]))
    const [isFeatureEnable, SetIsEnable] = useState(true) 

    useEffect(() => {
        window.addEventListener('TabLink-invoice', handleTabEvent);
        window.addEventListener('btn-invoice-show-pdf', handleBtnEvent);

        return () => {
            window.removeEventListener('TabLink-invoice', handleTabEvent);
            window.removeEventListener('btn-invoice-show-pdf', handleBtnEvent);
        };
    }, []);

    useEffect(() => {
        const invoicesSuscription = invoiceRepository.getAll(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                if (data?.length == 0){
                    SetInvoices(EmptyArray)
                } else {
                    SetInvoices(data)
                }
            },
            (error) => {
                console.error('Error fetching referrals:', error);
                SetInvoices(undefined);
            }
        ); 

        const isEnableSuscription = invoiceRepository.isEnable(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                if ("electronic_invoice" in data && data["electronic_invoice"] == 1) {
                    SetIsEnable(true)
                }
                else {
                    SetIsEnable(false)
                }
            },
            (error) => {
                console.error('Error fetching invoiceInfoSuscription:', error);
            }
        );

        return () => {
            isEnableSuscription.unsubscribe()
            invoicesSuscription.unsubscribe() 
        };
    }, [])

    function handleTabEvent(event) {
        setTab(event.detail.eventKey)
    }

    function handleBtnEvent(event) {
        const pdfSuscription = invoiceRepository.getPdf(userSingleton.uid, userSingleton.cid, event.detail.data.cude).subscribe(
            (data) => {
                const blob = createPdfBlob(data["data"]);
                const pdfUrl = createPdfUrl(blob);
                dispatchCustomEvent('loading-btn-invoice-show-pdf')
                window.open(pdfUrl)
            },
            (error) => {
                console.error('Error fetching invoiceInfoSuscription:', error);
                dispatchCustomEvent('loading-btn-invoice-show-pdf')
            }
        );
        return () => pdfSuscription.unsubscribe()
    }

    return (
        <GeneralLayout>
            <div>
                <ButtonGroup />
                <br></br>
                <Tab tabKey="invoice" tabs={tabs} />
                {
                    tab === "invoices" && (
                        <div className='invoice-tab-container'>
                            <Table
                                maxHeight="70vh"
                                headersList={[
                                    "Cliente",
                                    "Estado", 
                                    "Fecha",  
                                    "Email Status",
                                    "Consecutivo",
                                    "Valor",
                                    "Acciones"
                                ]}
                                data={invoices}
                                excludeList={["cude", "number", "time", "prefix"]}
                            >
                                <ButtonAction enableLoadingAnimation={true} btnKey="invoice-show-pdf">Pdf</ButtonAction>
                            </Table>
                        </div>
                    )
                }
                {
                    tab === "settings" && isFeatureEnable && (
                        <InvoiceDataForm></InvoiceDataForm>
                    )
                }
                {
                    tab === "settings" && !isFeatureEnable && (
                        <div class="container" style={{ position: "relative", marginTop: "30%" }}>
                            <p>Para activar  esta funcionalidad, por favor contacte con un asesor y solicite la activacion.</p>
                            <ButtonAction onClick={() => enviarMensaje()}>
                                Activar factura electronica
                            </ButtonAction>
                        </div>
                    )
                }
            </div>
        </GeneralLayout>
    )

}

export default Invoice