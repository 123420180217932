import './style.css'
import { useEffect, useState } from 'react'

import Nav from 'react-bootstrap/Nav';

function TabLink(props) { 

    function dispatchCustomEvent() {
        const event = new CustomEvent(props.tabKey ? 'TabLink-'+props.tabKey : 'TabLink', {
            detail: { eventKey: props.eventKey, title: props.title },
            bubbles: true,
            cancelable: true,
        });
        window.dispatchEvent(event)
    }

    return (
        <Nav.Item>
            <Nav.Link onClick={dispatchCustomEvent} eventKey={props.eventKey}>{props.title}</Nav.Link> 
        </Nav.Item>
    )

}

export default TabLink
