
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


//app/views Import  
import Login from '../app/views/login/view'
import Register from '../app/views/register/view' 
import Landing from '../app/views/landing/view';

import { Admin } from './admin'


export class Incognit extends Admin{
    
    constructor() {
        super(); 
    }

    getRoutes() {
        return (
            <Router>
                <Routes> 
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} /> 
                    <Route path="/" element={<Landing />} />
                    <Route path="*" element={<Landing />} />
                </Routes>
            </Router>
        )
    }

}