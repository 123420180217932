//Custo css import 
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

function LoadingBtn(props) {

    const [loading, SetLoading] = useState(false)

    useEffect(()=>{
        SetLoading(props.loading)
    }, [props.loading])

    return (
        <div>
            {
                loading === true && (<ReactLoading height={props.height ? props.height: 25} width={props.width ? props.width:25} />)
            }
            {
                loading === false && (props.children)
            } 
        </div>
    )
}

export default LoadingBtn