import React, { useEffect, useState } from 'react';
import './style.css';

function Modal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('modal'+props.id, openModal);
    window.addEventListener('close-all-modal', closeModal);
    window.addEventListener('close-'+props.id+'-modal', closeModal);
    return () => {
      window.removeEventListener('modal'+props.id, openModal);
      window.removeEventListener('close-all-modal', closeModal);
      window.removeEventListener('close-'+props.id+'-modal', closeModal);
    };
  }, [props]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div >
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
