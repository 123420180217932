import { InvoiceRepository } from '../../domain/models/invoice/gateway/invoice-repository';
import { Invoice, InvoicePDF } from '../../domain/models/invoice/invoice';
import { InvoiceInformation } from '../../domain/models/invoice/invoice-information';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class InvoiceRepositoryImp extends InvoiceRepository {
  getAll(uid: string, cid: string | null): Observable<Invoice[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Invoice[]>(`invoice`);
  }
  
  getInvoiceInformation(uid: string, cid: string | null): Observable<InvoiceInformation> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<InvoiceInformation>(`invoice/data`);
  }

  updateInvoiceInformation(uid: string, cid: string | null, data: any): Observable<InvoiceInformation> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.put<InvoiceInformation>(`invoice/data`, data);
  }

  getResolutions(uid: string, cid: string | null): Observable<string[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<string[]>(`invoice/resolutions`);
  }

  isEnable(uid: string, cid: string | null): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<any>(`invoice/enable`);
  }

  getPdf(uid: string, cid: string | null, cude: string): Observable<InvoicePDF> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<InvoicePDF>(`invoice/pdf/${cude}`);
  }
}