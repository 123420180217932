import { getLocalStorage, setLocalStorage } from "../../../utils/helper";
import { get, post, del } from "../../../utils/infra";
 

export async function getData(type="client") {  
    let res = await get(process.env.REACT_APP_URL_POS+'/v1/thirds?type='+type)    
    return res
} 

export async function getIdType() {  
    var aux_taxes = getLocalStorage('idType') 
    if (aux_taxes.length === undefined) {
        let res = await get(process.env.REACT_APP_URL_POS+'/v1/idType')   
        setLocalStorage('idType', res)
        return res
    }
    else{
        return aux_taxes
    } 
} 

export async function getThirdType() {  
    let res = await get(process.env.REACT_APP_URL_POS+'/v1/thirdType')   
    return res
} 


export async function createNewUser(data, thirdType) {    
    var response = {}
    var send = true
    Object.keys(data).map((key)=>{ 
        if (data[key] == ""){ 
            send = false
            response = {"status":false, "message":"Datos incompletos", "data": null}
        }
    })
    if (send){
        console.log("thirdType", thirdType)
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/thirds?type='+thirdType, data)   
        response = {"status":true, "message":"Tercero creado", "data": res} 
        if (!Array.isArray(res)){ 
            const messages = {
                'conflict': "Existe un cliente con ese numero de identificacion...",
                'limit_reached': "Upss.. Alcansaste tu limite. Mejora tu plan y administra tus clientes sin limites"
            }
            response = {"status": false, "message": messages[res], "data": []}
        }
    }
    return response
}

export async function deleteThird(id, thirdType) { 
    let res = await del(process.env.REACT_APP_URL_POS+'/v1/thirds?id='+id+'&type='+thirdType)  
    return res 
}