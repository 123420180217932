import { ParametrizationRepository } from '../../domain/models/parametrization/gateway/parametrization-repository';
import { Accounts, Parametrization } from '../../domain/models/parametrization/parametrization';
import { ProductRepository } from '../../domain/models/products/gateway/products-repository';
import { PayMethods, Product } from '../../domain/models/products/products';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class ParameterizationRepositoryImp extends ParametrizationRepository {
  get(uid: string, cid: string | null): Observable<Parametrization[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Parametrization[]>(`v1/parametrization`);
  }  
  getAccounts(uid: string, cid: string | null): Observable<Accounts[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Accounts[]>(`v1/accounts`);
  }  
}