import { TaxRepository } from '../../domain/models/taxes/gateway/taxes-repository';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';
import { Tax } from '../../domain/models/taxes/taxes';

export default class TaxRepositoryImp extends TaxRepository {
  get(uid: string, cid: string | null, _type : string): Observable<Tax[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Tax[]>(`tax?type=${_type}`);
  } 
}