import { Form } from "react-bootstrap"
import ButtonLoading from "../../atomos/buttons/buttonLoading/view"
import { BiSave } from "react-icons/bi"
import { userSingleton } from "../../classes/User";
import { useEffect, useState } from "react";
import InvoiceRepositoryImp from '../../infrastructure/invoice/invoice-repository-implementation'
import { dispatchCustomEvent } from "../../utils/dispatch";
import LoadingScreen from "../../atomos/loading/loadingScreen/view";

function InvoiceDataForm(params) {

    const invoiceRepository = new InvoiceRepositoryImp();
    const [invoiceData, SetInvoiceInfo] = useState(undefined)
    const [resolutions, SetResolutions] = useState(undefined)

    useEffect(() => {

        const invoiceInfoSuscription = invoiceRepository.getInvoiceInformation(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                SetInvoiceInfo(data);
            },
            (error) => {
                console.error('Error fetching invoiceInfoSuscription:', error);
            }
        );

        const resolutionSuscription = invoiceRepository.getResolutions(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                SetResolutions(data);
            },
            (error) => {
                console.error('Error fetching invoiceInfoSuscription:', error);
            }
        );

        return () => {
            invoiceInfoSuscription.unsubscribe()
            resolutionSuscription.unsubscribe()
        };
    }, [])

    function updateInvoiceData() {
        var resolution = window.document.getElementById("electronic-invoice-resolution").value
        var resolutionText = window.document.getElementById("electronic-invoice-resolution-text").value
        var headNote = window.document.getElementById("electronic-invoice-header").value
        var footerNote = window.document.getElementById("electronic-invoice-footer").value
        var notes = window.document.getElementById("electronic-invoice-notes").value
        var data = {
            "resolution": resolution,
            "resolution_text": resolutionText,
            "head_note": headNote,
            "foot_note": footerNote,
            "notes": notes
        }
        const updateInvoiceDataSuscription = invoiceRepository.updateInvoiceInformation(userSingleton.uid, userSingleton.cid, data).subscribe(
            (data) => {
                SetInvoiceInfo(data)
                dispatchCustomEvent('loading-btn-update-invoice-info', [])
            },
            (error) => {
                console.error("Error actualizando campos de factura electronica: ", error)
                dispatchCustomEvent('loading-btn-update-invoice-info', [])
            }
        )
        return () => updateInvoiceDataSuscription.unsubscribe()
    }

    return (
        <div>
            {
                <div className='invoice-form-settings'>
                    {
                        invoiceData == undefined && (
                            <LoadingScreen></LoadingScreen>
                        )
                    }
                    {
                        invoiceData != undefined && (
                            <Form action="javascript:void(0);" onSubmit={() => updateInvoiceData()} >
                                <Form.Group >
                                    <Form.Label>Resolucion</Form.Label>
                                    <Form.Select id="electronic-invoice-resolution">
                                        {
                                            Array.isArray(resolutions) ?
                                                resolutions.map((resolution) => {
                                                    return (
                                                        <option value={resolution}>{resolution}</option>
                                                    )
                                                }) : <option>Error</option>
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label>Texto de resolucion</Form.Label>
                                    <Form.Control placeholder={invoiceData ? invoiceData.resolution_text : ''} id="electronic-invoice-resolution-text"></Form.Control>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label>Encabezado</Form.Label>
                                    <Form.Control placeholder={invoiceData ? invoiceData.head_note : ''} id="electronic-invoice-header"></Form.Control>
                                </Form.Group>
                                <Form.Group  >
                                    <Form.Label>Pie de pagina</Form.Label>
                                    <Form.Control placeholder={invoiceData ? invoiceData.foot_note : ''} id="electronic-invoice-footer"></Form.Control>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label>Notas</Form.Label>
                                    <Form.Control placeholder={invoiceData ? invoiceData.notes : ''} id="electronic-invoice-notes"></Form.Control>
                                </Form.Group>
                                <ButtonLoading
                                    id="update-invoice-info"
                                    onClick={updateInvoiceData}
                                    showLoading={true}
                                >
                                    <BiSave></BiSave> Guardar
                                </ButtonLoading>
                            </Form>
                        )
                    }
                </div>
            }
        </div>

    )
}

export default InvoiceDataForm