import { userSingleton } from "../../classes/User";
import { get, post } from "../../utils/infra"; 

export async function addAccounts(data, id) {  
    data.preventDefault() 
    var js = {
        "code": parseInt(data.target[0].value),
        "description": data.target[1].value,
        "accounts_id": id
    }  
    let res = await post(process.env.REACT_APP_URL_POS+'/v1/accounts?uid='+userSingleton.uid, js)  
    return res
}

export async function getAccounts(id) { 
    let res = await get(process.env.REACT_APP_URL_POS+'/v1/accounts?uid='+userSingleton.uid+'&account='+id) 
    return res
}