import { userSingleton } from "../../../classes/User";
import TaxRepositoryImp from "../../../infrastructure/taxes/tax-repository-implementation";

const taxRepository = new TaxRepositoryImp();

function getTaxes(type, func) {  
    const taxSuscription = taxRepository.get(userSingleton.uid, userSingleton.cid, type).subscribe(
        (data) => { 
            func(data) 
        },
        (error) => {
            console.error(error)
        }
    )
    return () => taxSuscription.unsubscribe()       
}

export { getTaxes }