// import './style.css' 
import { Badge, Button } from 'react-bootstrap'  
import { dispatchCustomEvent } from '../../../utils/dispatch';
import "./style.css"
import { useEffect, useState } from 'react';
import LoadingBtn from '../../loading/loadingBtn/view';

function ButtonBasic(props) {

    const [loading, setLoading] = useState(false)
    const [badge, setBadge] = useState(0)
    
    useEffect(() => {
        window.addEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent); 
        return () => {
            window.removeEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent); 
        };
    }, [])

    useEffect(() => {setBadge(props.badge ? props.badge : 0)}, [props.badge])

    function handleEvent() {
        setLoading(false)
    }

    function handleClick(){ 
        dispatchCustomEvent('modal'+props.modalID,[])
        if(props.onClick?props.onClick:false) {props.onClick()}
        if(props.showLoading?props.showLoading:false) {setLoading(props.showLoading)}
    }

    return (
        <Button
            style={props.style?props.style:{}}
            id={props.id ? "modal-btn-basic-" + props.id : "modal-btn-basic"}
            aria-disabled={true}
            className={props.type == "topbar" ? ('buttonTopBar') : ('buttonBasic')}
            onClick={handleClick}
            type="button"
        >
            <LoadingBtn loading={loading}>
                {props.children}
                {props.title}
                {
                    badge > 0 && (<Badge style={{marginLeft:"10px"}} bg="danger">{badge}</Badge>)
                }  
            </LoadingBtn> 
            
        </Button>
    )

}

export default ButtonBasic
