//Custo css import
import './style.css'

import TopBar from '../../organismos/topBar/view' 

function GeneralLayout(props) { 
    return (
        <div className='general-layout'>
            <TopBar/>
            { 
                <div>
                    {props.children}
                </div>
            }
        </div>
    )

}

export default GeneralLayout