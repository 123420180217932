import { userSingleton } from "../../../classes/User";
import SuscriptionRepositoryImp from "../../../infrastructure/suscriptions/suscription-repository-implementation"; 

const repository = new SuscriptionRepositoryImp();

function getSuscription(func) {  
    const suscription = repository.get(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => {  
            func(data["data"]) 
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()       
}

export { getSuscription }