import { carSingleton } from '../../classes/Carrito';

export function handleSubTotal(selectedMethods) { 
    var totalToPay = parseFloat(carSingleton.getTotal())
    var sumPayMethods = 0
    selectedMethods.map((id) => {
        sumPayMethods += parseFloat(window.document.getElementById("pay-"+id).value)
    })
    var diferenceTotalSubtotal = totalToPay - sumPayMethods
    if (isNaN(diferenceTotalSubtotal)) {
        diferenceTotalSubtotal = totalToPay
    }
    if (diferenceTotalSubtotal >= 0) {
        return [0, diferenceTotalSubtotal]
    } else {
        return [diferenceTotalSubtotal, 0]
    }
}