// import './style.css'
import { Button } from 'react-bootstrap'
import LoadingBtn from '../../loading/loadingBtn/view';
import { useEffect, useState } from 'react';
import { dispatchCustomEvent } from '../../../utils/dispatch';
import "./style.css"

function ButtonAction(props) {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.addEventListener(props.btnKey ? 'loading-btn-'+props.btnKey : 'loading-btn', handleEvent);
        return () => {
            window.removeEventListener(props.btnKey ? 'loading-btn-'+props.btnKey : 'loading-btn', handleEvent);
        };
    }, [])

    function handleEvent() {
        setLoading(false)
    }

    function handleClick(){
        dispatchCustomEvent('modal'+props.modalID,[])
        if(props.onClick?props.onClick:false) {props.onClick()}
        if(props.showLoading?props.showLoading:false) {setLoading(props.showLoading)}
    }

    function eventCustom() {
        if (props.enableLoadingAnimation ? props.enableLoadingAnimation : false){
            setLoading(true)
        }
        dispatchCustomEvent(props.btnKey ? 'btn-'+props.btnKey : 'btn', props.data)
        handleClick()
    }

    return (
        <Button
            hidden={props.hidden ? props.hidden : false}
            className="btn-action"
            onClick={eventCustom}
        >
            <LoadingBtn loading={loading}>{props.children}</LoadingBtn>
        </Button>
    )

}

export default ButtonAction

// // import './style.css'
// import { Button } from 'react-bootstrap'
// import LoadingBtn from '../../loading/loadingBtn/view';
// import { useEffect, useState } from 'react';
// import { dispatchCustomEvent } from '../../../utils/dispatch';
// import "./style.css"

// function ButtonAction(props) {

//     const [loading, setLoading] = useState(false)
//     const [badge, setBadge] = useState(0)

//     useEffect(() => {
//         window.addEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent);
//         return () => {
//             window.removeEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent);
//         };
//     }, [])

//     useEffect(() => {setBadge(props.badge ? props.badge : 0)}, [props.badge])

//     function handleEvent() {
//         setLoading(false)
//     }

//     function handleClick(){
//         dispatchCustomEvent('modal'+props.modalID,[])
//         if(props.onClick?props.onClick:false) {props.onClick()}
//         if(props.showLoading?props.showLoading:false) {setLoading(props.showLoading)}
//     }

//     return (
//         <Button
//             id={props.id ? "modal-btn-basic-" + props.id : "modal-btn-basic"}
//             aria-disabled={true}
//             className="btn-action"
//             onClick={handleClick}
//             type="button"
//             hidden={props.hidden ? props.hidden : false}
//         >
//             <LoadingBtn loading={loading}>
//                 {props.children}
//                 {props.title}
//             </LoadingBtn>

//         </Button>
//     )

// }

// export default ButtonAction
