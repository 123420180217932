import React from 'react';
import { useEffect, useState } from 'react'
import './style.css'
import LoadingScreen from '../../atomos/loading/loadingScreen/view';
import Tooltip from '../../atomos/tooltip/view';
import { EmptyArray } from '../../domain/models/empty/empty';

const Table = ({ excludeList = [], headersList = [], emptyMessage, emptyChild, onReload, data, maxHeight, children}) => {

    const [headers, SetHeaders] = useState([])

    useEffect(() => {
        if (data?.length > 0 && headersList?.length == 0) {
            SetHeaders(Object.keys(data ? data[0] : []))
        } else {
            SetHeaders(headersList ? headersList : [])
        }
    }, [data])

    return (
        <div className='table-container'>
            {
                data ?
                <div>
                    {
                        data.length > 0 && data != EmptyArray && (
                            <table class="table">
                                <thead>
                                    <tr>
                                        {
                                            headers?.length > 0 && (
                                                headers?.map((item, idx) => {
                                                    if (!excludeList.includes(item)) {
                                                        return (
                                                            <th key={idx} scope="col">{item}</th>
                                                        )
                                                    }
                                                })
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody style={{ maxHeight: maxHeight ? maxHeight : '50vh'}}>
                                    {
                                        headers.length > 0 && (
                                                data.map(item => {
                                                    const childrenWithProps = React.Children.map(children, child => {
                                                        return React.cloneElement(child, { data: item });
                                                    });
                                                    return (
                                                        <tr>
                                                            {
                                                                Object.keys(item).map((key) => {
                                                                    if (!excludeList.includes(key)) {
                                                                        return (
                                                                            <td scope="row"><Tooltip text={item[key]}>{item[key]}</Tooltip></td>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                children != undefined && (<td scope="row" style={{ width: "auto", display:"flex", justifyContent:"space-around", gap:"1px"}}>{childrenWithProps}</td>)
                                                            }
                                                        </tr>
                                                    )
                                                })
                                        )
                                    }
                                </tbody>
                            </table>
                        )
                    }
                    {
                        data.length > 0 && data == EmptyArray && (
                            <div className='empty-status'>
                                <p>{emptyMessage ? emptyMessage : "Aun no tienes datos"}</p>
                                {emptyChild}
                            </div>
                        )
                    }
                    {
                        data.length == 0 && (
                            <LoadingScreen></LoadingScreen>
                        )
                    }
                </div>
                : <div>volver a cargar</div>
            }
        </div>
    )

}

export default Table
