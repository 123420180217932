import { Spinner } from 'react-bootstrap'
import './style.css' 

function LoadingScreen(props) { 
    return (
        <div className='loadingScreen'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default LoadingScreen