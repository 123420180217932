import { dateFormat, getCookie } from "../utils/helper";
import { get, post } from "../utils/infra";
import { userSingleton } from "./User";

class Carrito {
    
    constructor(client_id) { 
        this.client_id = client_id
        this.tax_sumary =  []
        this.pay_methods = [] 
        this.items = []
        this.bill = [{"bill_id": 0}]
    }

    getCar(){ 
        var key = "skutmp"
        this.result = this.items.reduce((result, currentValue) => {    
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue); 
            result[currentValue[key]].total = 0
            result[currentValue[key]].id = result[currentValue[key]][0].id
            result[currentValue[key]].name = result[currentValue[key]][0].name
            result[currentValue[key]].sku = result[currentValue[key]][0].sku
            result[currentValue[key]].value = result[currentValue[key]][0].value
            result[currentValue[key]].count = result[currentValue[key]].length
            result[currentValue[key]].tax = result[currentValue[key]][0].sell_tax
            result[currentValue[key]].sell_taxes_id = result[currentValue[key]][0].sell_taxes_id
            result[currentValue[key]].subtotal = result[currentValue[key]].count * result[currentValue[key]][0].value //Calculo subtotal
            result[currentValue[key]].tax_subtotal = (result[currentValue[key]].subtotal * result[currentValue[key]][0].sell_tax) / (1 + result[currentValue[key]][0].sell_tax)
            result[currentValue[key]].map(() => {
                result[currentValue[key]].total = result[currentValue[key]].subtotal 
            })
            return result;
        }, {});
        return this.result
    }  

    setProductPrice(skutmp, price){  
        var aux = this.items
        aux = aux.map((itm, idx) => { if (itm.skutmp == skutmp){  itm.value = price } }) 
        return []
    }

    getTaxSumary(){
        var key = "sell_taxes_id" 
        this.tax_sumary = this.items.reduce((result, currentValue) => {    
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue); 
            result[currentValue[key]].sell_tax = result[currentValue[key]][0].sell_tax
            result[currentValue[key]].sell_taxes_id = result[currentValue[key]][0].sell_taxes_id
            result[currentValue[key]].value = 0
            result[currentValue[key]].map((itm) => {
                result[currentValue[key]].value = result[currentValue[key]].value + itm.value
            })
            result[currentValue[key]].tax_subtotal = (result[currentValue[key]].value * result[currentValue[key]][0].sell_tax) / (1 + result[currentValue[key]][0].sell_tax)
            return result;
        }, {});
        return this.tax_sumary
    }
    
    getItems(){
        return this.items
    }

    add(item) {
        var aux = item
        aux.skutmp = aux.sku + aux.value 
        this.items.push(aux) 
        return this.getCar()
    } 

    remove(item){
        var index = this.items.indexOf(item) 
        delete this.items[index]; 
        return this.getCar()
    }
    
    clean(){
        this.tax_sumary =  []
        this.pay_methods = []
        this.items = []
        this.bill = [{"bill_id": 0}]
    }

    setMethod(data) {  
        this.pay_methods = data
        return data
    }  

    setTotal(total) {
        this.total = total
    }

    getTotal() {
        return this.total 
    }

    async getBill() { 
        let res = await get(process.env.REACT_APP_URL_POS+'/v1/sell?uid='+userSingleton.uid) 
        return res
    }

    async pagar(tercero = NaN, electronicBill=false) {  
        var data ={
            "items": this.result,
            "paymethods": this.pay_methods,
            "third": tercero,
            "modelType": "sell"
        }
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/sell', data)
        var response = {"status": false, "message": res} 
        if (typeof res != 'string') {
            this.item = []
            if (electronicBill) {
                await post(process.env.REACT_APP_URL_POS+'/v1/documents', 
                    {"model": "invoice", "id": res["bill_id"]}
                ) 
            }
            this.bill = await this.getBill()
            response = {"status": true, "message":"success", "data": this.bill}
        }       
        return response
    }

    async createPreOrder(tercero, expirtation_date, buy_date, retention) {  
        var data ={
            "items": this.result,
            "paymethods": this.pay_methods,
            "third": tercero,
            "modelType": "preorder",
            "expirtation_date": expirtation_date,
            "buy_date": dateFormat(buy_date),
            "retention": retention
        }  
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/preorder', data)
        return res 
    }

    async comprar(items, document) {  
        var data ={
            "items": items,
            "document": document
        }  
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/compra', data)
        return res 
    }

    
    
}  

var instance = getCookie("data") ? JSON.parse(getCookie("data")) : null;
 
function createInstance(data) {
    if (carSingleton != null && carSingleton instanceof carSingleton) {
        return carSingleton
    } else {
        var object = new Carrito(data); 
        return object;
    }
} 

export var carSingleton = instance ? (instance.uid ? createInstance(instance.uid) : createInstance(null)) : createInstance(null)
// export var carSingleton = createInstance(1)