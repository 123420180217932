import { del, get, post, put } from "../utils/infra";  
import { userSingleton } from './User';

export class Producto {
    
    constructor(sku) {
        this.sku = sku; 
    } 

    async new(user_id, sku, name, value, sell_taxes_id){ 
        var data = {
            "user_id": user_id,
            "sku": sku,
            "name": name, 
            "value": value,
            "sell_taxes_id": sell_taxes_id,
        }    
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/producto?uid='+userSingleton.uid, data)
        return res
    } 

    async edit(name, value){ 
        var data = {
            "user_id": '0',
            "sku": this.sku,
            "name": name, 
            "value": value,
            "sell_taxes_id": 'sell_taxes_id',
        }   
        let res = await put(process.env.REACT_APP_URL_POS+'/v1/producto?uid='+userSingleton.uid, data)
        return res
    } 

    async trash(){ 
        var data = { 
            "sku": this.sku
        }   
        let res = await del(process.env.REACT_APP_URL_POS+'/v1/producto?uid='+userSingleton.uid, data)
        return res
    }

    async get(){ 
        // res = await get(process.env.REACT_APP_URL_POS+'/v1/user?uid='+this.uid)
        // return res
    }

} 
 