//Custo css import
import './style.css'

//Boostrap import  
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Loading from '../../atomos/loading/loadingBtn/view';


//React import 
import { useState } from 'react';

import { getAccounts, addAccounts } from './controller';

//Widgets import  
import { AiOutlineSetting } from 'react-icons/ai'; 
import ModalBtn from '../../moleculas/modalButton/hub/view';


function Cuentas(props) {

    const [open, SetOpen] = useState(true)
    const [accounts, SetAccounts] = useState([])
    const [loading, SetLoading] = useState(false)

    function handleAddAcount(res) {
        if (res.length > 0) {
            SetAccounts(res)
        }
        SetLoading(false)
    }

    function update() {
        getAccounts(props.account.id).then((res) => { SetAccounts(res) })
    }

    return (
        <div>
            <ModalBtn 
                id={"account"+props.account.id}
                className='action-btn'
                title={<AiOutlineSetting/>}
                onClick={update}
                type="action"
            >
                <h2 style={{ margin: "20px" }}>{props.account.description}</h2>
                <div>
                    <Form action="javascript:void(0);" style={{ display: "flex", marginTop: "30px" }}
                        onSubmit={(data) => [SetLoading(true), addAccounts(data, props.account.id).then((res) => [handleAddAcount(res)])]}
                    >
                        <p style={{ fontSize: "20px", fontWeight: "bold", margin: "5px", marginLeft: "20px" }}>{props.account.account}</p>
                        <Form.Group className="mb-3" style={{ "width": "100px", marginLeft: "10px" }}>
                            <Form.Control type="text" placeholder="00" />
                        </Form.Group>
                        <Form.Group className="mb-3" style={{ "width": "300px", marginLeft: "10px" }}>
                            <Form.Control type="text" placeholder="Descripcion" />
                        </Form.Group>
                        <Button variant="primary" type="submit" style={{ width: "100px", height: "38px", marginLeft: "10px" }}>
                            <Loading loading={loading}> Agregar  </Loading>
                        </Button>
                    </Form>
                </div>

                <div style={{ textAlign: "left" }}>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: "200px" }}>Cuenta</th>
                                <th scope="col">Descripcion</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div style={{ textAlign: "left", overflowY: "scroll", height: "54vh" }}>
                    <table class="table">
                        <tbody>
                            {
                                accounts.map((account) => {
                                    return (
                                        <tr hidden={account.is_account === 0}>
                                            <td style={{ width: "200px" }}>{account.account}</td>
                                            <td style={{ width: "500px" }}>{account.description}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </ModalBtn>
        </div>
    )
}

export default Cuentas