import { getCookie, getLocalStorage, setLocalStorage } from "../utils/helper";
import { get, post } from "../utils/infra";

class User {
    constructor(uid) {
        this.uid = uid
        var aux = getLocalStorage('impersonate') 
        if (Object.keys(aux).length > 0) {
            this.cid = aux.cid
            this.roles_id = aux.role  
        } else {
            this.cid = uid
            this.roles_id = this.uid ? 1 : 0
        }
        this.data = {}
        this.sell_taxes = []
        this.buy_taxes = []
        this.pay_methods = []
        this.features = {}
    }
    
    setUid(uid) {
        this.uid = uid; 
    }  

    serRole(role){
        this.roles_id = role;
    }

    async new(data){  
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/user', data)
        return res
    }

    async get(){
        var aux = this.cid ? this.cid : this.uid
        if (Object.keys(this.data).length == 0){
            let res = await get(process.env.REACT_APP_URL_POS+'/v1/user?uid='+aux)
            if(res["status"] == undefined){
                this.data = res 
                return res
            }else{
                return this.get()
            }
        }else{
            return this.data
        } 
    }

    async getFeatures(){ 
        let res = await get(process.env.REACT_APP_URL_POS+'/v1/user/features')
        this.features = res 
        return res   
    }

    async impersonate(uid_to_impersonta, role){
        let res = await get(process.env.REACT_APP_URL_POS+'/v1/user?uid='+uid_to_impersonta)
        if(res["status"] == undefined){
            this.data = res 
            this.cid = uid_to_impersonta
            setLocalStorage('impersonate', {cid: this.cid, role: role}) 
            return res
        }else{
            return this.impersonate(uid_to_impersonta)
        }
    }

    async getSharedCompanies(){
        var aux = getLocalStorage('shared-companies') 
        if (aux.length == undefined) {
            let res = await get(process.env.REACT_APP_URL_POS+'/v1/shared?uid='+this.uid) 
            if(res["status"] == undefined){
                setLocalStorage('shared-companies', res)
                return res
            }else{
                return this.getSharedCompanies()
            }
        }
        else{
            return aux
        } 
    }

    async getTaxes(type){ 
        var aux_taxes = getLocalStorage(type+'-taxes')
        let res = await get(process.env.REACT_APP_URL_POS+'/v1/taxes?type='+type)
        return res 
    }  

    async payMethods(){ 
        if (this.pay_methods.length == 0){
            let res =await get(process.env.REACT_APP_URL_POS+'/v1/paymethods', this.result) 
            if(res.length != undefined){
                this.pay_methods = res
                return res
            }else{
                return this.payMethods()
            }
        }else{
            return this.pay_methods
        }   
    }

    async getSuscription() {
        let res = await get(process.env.REACT_APP_URL_POS+'/v1/suscription')
        return res 
    }

    async addResolution(data) { 
        let res = await post(process.env.REACT_APP_URL_POS+'/v1/resolution?uid='+userSingleton.uid, data)
        return res
    }

} 

var instance = getCookie("data") ? JSON.parse(getCookie("data")) : null;

function createInstance(uid) {
    var object = new User(uid);
    return object;
} 

export var userSingleton = instance ? (instance.uid ? createInstance(instance.uid) : createInstance(null)) : createInstance(null)

 