import { Observable } from 'rxjs';
import { Plan } from '../suscriptions';

export abstract class SuscriptionRepository {
  abstract get(uid: string, cid: string | null): Observable<Plan>;
  abstract suscribe(uid: string, cid: string | null, body: any): Observable<any>;
  abstract cancel(uid: string, cid: string | null): Observable<any>;
  abstract update(uid: string, cid: string | null, body : any): Observable<any>;
  abstract getAllPlans(uid: string, cid: string | null): Observable<Plan[]>;
}

