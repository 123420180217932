import { userSingleton } from "../../classes/User";
import { get, post } from "../../utils/infra"; 

export async function getAccounts() { 
    let res = await get(process.env.REACT_APP_URL_POS+'/v1/accounts?uid='+userSingleton.uid) 
    return res
}

export async function getParametrization(id) { 
    let res = await get(process.env.REACT_APP_URL_POS+'/v1/parametrization?uid='+userSingleton.uid) 
    return res
}

export async function setParametrization(users_accounts_id, users_accounts_id_old, param_type) { 
    var js = {
        "users_accounts_id": parseInt(users_accounts_id), 
        "users_accounts_id_old": parseInt(users_accounts_id_old), 
        "param_type": param_type 
    }    
    let res = await post(process.env.REACT_APP_URL_POS+'/v1/parametrization?uid='+userSingleton.uid, js)  
    return res
}
