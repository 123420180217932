import { ThirdsRepository } from '../../domain/models/thirds/gateway/thirds-repository';
import { Third } from '../../domain/models/thirds/third';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class ThirdsRepositoryImp extends ThirdsRepository {
  get(uid: string, cid: string | null, type: string): Observable<Third[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Third[]>(`v1/thirds?type=${type}`);
  }
}