//Classes
import { userSingleton } from "../../classes/User"
import './style.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { VscDebugContinueSmall } from 'react-icons/vsc';
import { AiOutlineCheck } from 'react-icons/ai';
import { getIdTypes } from './controller'
import { useEffect, useState } from "react";
import Loading from '../../atomos/loading/loadingBtn/view';
import {
    initMercadoPago,
    createCardToken,
    CardNumber,
    SecurityCode,
    ExpirationDate,
} from '@mercadopago/sdk-react';
import { getPlans } from "../../domain/usecase/suscriptions/get-all-plans";
import { createSuscription } from "../../domain/usecase/suscriptions/suscribe";
import CardToken from "../../shared/atomos/cardtoken";


function Planes(props) {

    const [plans, SetPlans] = useState([])
    const [selectedPlan, SetSelectedPlan] = useState([])
    const [step, setStep] = useState(1);  

    useEffect(() => { 
        getPlans(SetPlans)
    }, [])

    function handleNext(plan){
        if(plan?.price == 0){
            window.location.replace('/hub')
        }else{
            setStep(step + 1)
        }
    }

    const handlePrevious = () => {
        setStep(step - 1);
    }; 

    const handleSubmit = (event) => {
        event.preventDefault();
    }; 

    return (
        <div style={{ maxHeight:"100vh", maxWidth:"100vw", overflow:"hidden" }}>
            <div className="land-top-bar">
                <h1># Easypos</h1>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="planes-progress">
                    <ProgressBar now={(step / 3) * 100} />
                </div>
                {step > 1 && (
                    <Button className="btn-atras" variant="secondary" onClick={handlePrevious}>
                        Volver
                    </Button>
                )}
                {step === 1 && (
                    <div className="planes-container">
                        <div className="container-box">
                            {
                                plans ?
                                    plans?.map((plan) => {
                                        return (
                                            <div className="land-pagos">
                                                <div style={{ height: "25%" }}>
                                                    <p className="land-plan-title">{plan.name?.charAt(0).toUpperCase() + plan.name?.slice(1)}</p>
                                                    <p className="land-plan-description">{plan?.price > 0 && ("Por " + plan?.price + " COP al mes")}</p>
                                                    <p className="land-plan-description">{plan?.price == 0 && ("Sin cargos menuales")}</p>
                                                </div>
                                                <hr style={{ margin: "5px", borderWidth: "1px" }}></hr>
                                                <div style={{ height: "45%", padding: "10px" }}>
                                                    {
                                                        plan.information.data?.map((item) => {
                                                            return (
                                                                <p className="land-plan-item"> <AiOutlineCheck /> {item}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div style={{ height: "25%" }}>
                                                    <Button style={props.style} onClick={() => [SetSelectedPlan([plan]), handleNext(plan)]} className="land-start-btn" >{props.text ? props.text : "Seleccionar"}<VscDebugContinueSmall></VscDebugContinueSmall></Button>
                                                </div>
                                            </div>
                                        )
                                    }) : <div></div>
                            }
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className="formulario-plane"> 
                        <div style={{width:"50%", padding:"40px 40px"}}>
                            <CardToken type="suscribe" plan={selectedPlan}></CardToken>
                        </div>
                        <div className="planes-container land-pagos-planes" style={{backgroundColor:"rgba(0,0,0,0)"}}>
                            {
                                selectedPlan ?
                                selectedPlan?.map((plan) => {
                                        return (
                                            <div className="land-pagos">
                                                <div style={{ height: "25%" }}>
                                                    <p className="land-plan-title">{plan.name?.charAt(0).toUpperCase() + plan.name?.slice(1)}</p>
                                                    <p className="land-plan-description">{plan?.price > 0 && ("Por " + plan?.price + " COP al mes")}</p>
                                                    <p className="land-plan-description">{plan?.price == 0 && ("Sin cargos menuales")}</p>
                                                </div>
                                                <hr style={{ margin: "5px", borderWidth: "1px" }}></hr>
                                                <div style={{ height: "45%", padding: "10px" }}>
                                                    {
                                                        plan.information.data?.map((item) => {
                                                            return (
                                                                <p className="land-plan-item"> <AiOutlineCheck /> {item}</p>
                                                            )
                                                        })
                                                    }
                                                </div> 
                                            </div>
                                        )
                                    }) : <div></div>
                            }
                        </div>
                    </div>
                )} 

            </Form>
        </div>
    )

}

export default Planes