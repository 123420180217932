import React, { useEffect, useState } from 'react'
import { useUserAuth } from "../../../AuthContext";

//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';

//Controller import
import { userSingleton } from '../../classes/User'
import Loading from '../../atomos/loading/loadingBtn/view';
import { BiSave } from 'react-icons/bi';
import { getIdType } from '../../thirds/organismos/thirdsTable/controller';

function Register() {

    const { signUp } = useUserAuth();

    const [errorMsg, SetErrorMsg] = useState('')
    const [idTypes, SetidTypes] = useState('')
    const [loading, SetLoading] = useState(false)

    const [step, setStep] = useState(1);

    const [formData, setFormData] = useState({});


    const handleInputChange = (event) => {
        event.preventDefault()
        setFormData({ ...formData, [event.target.id]: event.target.value });
    };

    async function signup(event) {
        event.preventDefault()
        formData["register-document-type"] = window.document.getElementById("register-document-type")?.value ? window.document.getElementById("register-document-type")?.value : "2"

        var data = {
            name: formData['register-name'],
            companyName: formData['register-company-name'],
            email: formData['register-email'],
            password: formData['register-password'],
            document: formData['register-document'],
            phone: formData['register-phone'],
            address: formData['register-address'],
            terms: true,
            document_type: formData["register-document-type"]
        }

        // formData['register-check']

        var aux = true

        if (aux) {
            SetLoading(!loading)
            signUp(formData['register-email'], formData['register-password']).then((res) => {
                data["uid"] = res.user.uid
                userSingleton.setUid(res.user.uid)
                userSingleton.new(data).then(() => {
                    window.location.replace('/planes')
                })
            }).catch((e) => {
                if (e.message.includes('auth/email-already-in-use')) {
                    SetErrorMsg("Ya existe una cuenta con este email")
                }
            })
        }
    }

    function handleNext() {
        setStep(step + 1)
    }

    const handlePrevious = () => {
        setStep(step - 1);
    };

    useEffect(() => {
        var email = window.location.search.replace("?email=", "");
        if (email != '') {
            var email_aux = window.document.getElementById("register-email")
            email_aux.value = email
        }
        getIdType().then((res) => {
            SetidTypes(res)
        })
    }, [])

    return (

        <div className='registerinbox'>
            <div className="land-top-bar">
                <h1 onClick={() => window.location.replace('/')}># Easypos</h1>
            </div>
            <h2 style={{ fontSize: "30px", fontWeight: "bolder", marginTop: "50px" }}>Registrate, Gestiona y potencia tu negocio</h2>
            <Form className='register-form' id="register-form" action="javascript:void(0);" onSubmit={(data) => [data.preventDefault(), signup(data)]}>

                <div className="planes-progress">
                    <ProgressBar now={(step / 3) * 100} />
                </div>
                {
                    step > 1 && (
                        <Button onClick={() => { handlePrevious() }} className='atras-register'>
                            Volver
                        </Button>
                    )
                }
                {
                    step === 1 && (
                        <div>

                            <Form.Group className="mb-3"  >
                                <Form.Label>Cual es el nombre de tu negocio ?</Form.Label>
                                <Form.Control onChange={handleInputChange} id='register-company-name' type="text" placeholder="Nombre del negocio" />
                            </Form.Group>

                            <Form.Group className="mb-3"  >
                                <Form.Label>A que email te puedo enviar informacion?</Form.Label>
                                <Form.Control  onChange={handleInputChange}  id='register-email' type="email" placeholder="Email" />
                                <Form.Text hidden={!errorMsg.includes('email')} style={{ color: "red" }}>{errorMsg ? errorMsg : ''}</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3"  >
                                <Form.Label>Elije una contraseña para tu cuenta</Form.Label>
                                <Form.Control  onChange={handleInputChange}  id='register-password' type="password" placeholder="Contraseña" />
                            </Form.Group>

                            <Button onClick={() => { handleNext() }} className='register-btn-save'>
                                Continuar
                            </Button>
                        </div>
                    )
                }

                {
                    step === 2 && (
                        <div>

                            <Form.Group className="mb-3"  >
                                <Form.Label>Como te llamas?</Form.Label>
                                <Form.Control  onChange={handleInputChange}  id='register-name' type="text" placeholder="Tu Nombre" />
                            </Form.Group>

                            <Form.Group className="mb-3"  >
                                <Form.Label>Tu documento de identidad o el de tu negocio?</Form.Label>
                                <div style={{ display: "flex" }}>
                                    <Form.Select  onChange={handleInputChange}  style={{ width: "70px" }} id="register-document-type" name="color" >
                                        {
                                            idTypes ?
                                                idTypes.map((item) => {
                                                    return (
                                                        <option value={item.id}>{item.type}</option>
                                                    )
                                                }) : <div></div>
                                        }
                                    </Form.Select>
                                    <Form.Control  onChange={handleInputChange}  id='register-document' type="text" placeholder="Documento" />
                                </div>
                            </Form.Group>
                            <Button onClick={() => { handleNext() }} className='register-btn-save'>
                                Continuar
                            </Button>
                        </div>
                    )
                }

{
                    step === 3 && (
                        <div>

                            <Form.Group className="mb-3"  >
                                <Form.Label>A que telefono podemos comunicarnos con tigo?</Form.Label>
                                <Form.Control  onChange={handleInputChange}  id='register-phone' type="text" placeholder="Numero de telefono" />
                            </Form.Group>

                            <Form.Group className="mb-3"  >
                                <Form.Label>Direccion</Form.Label>
                                <Form.Control  onChange={handleInputChange}  id='register-address' type="text" placeholder="Direccion del negocio" />
                            </Form.Group>

                            <Form.Group hidden={true} className="mb-3"  >
                                <Form.Check  onChange={handleInputChange}  id='register-check' type="checkbox" label="Acepto terminos y condiciones" />
                            </Form.Group>

                            <Button type="submit" className='register-btn-save'>
                                <Loading loading={loading}> <BiSave></BiSave> Empezar </Loading>
                            </Button>
                        </div>
                    )
                }
                <p hidden={!errorMsg.includes('completando')} style={{ color: "red", fontSize: "17px" }}>{errorMsg ? errorMsg : ''}</p>
            </Form>
        </div>
    )

}

export default Register
